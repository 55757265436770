import React from 'react';
import PropTypes from 'prop-types';
import { HeadingTag, Link, PreHeading } from 'components';
import ArrowRight from 'assets/arrowRight.svg';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

const SectionContent = ({
  headingClassName = 'title font-didot mt-7 color-from-bg',
  preHeadingClassName = 'text-black my-4',
  subHeadingClassName = 'text-black my-4',
  descriptionClassName,
  descriptionWrapperclassName = 'mt-8  color-from-bg',
  data,
  ...props
}) => {
  const { headingTitle, description, preHeading, subHeading, primaryCTA, secondaryCTA } = data || {};
  const { t } = useTranslation('common');
  const router = useRouter();

  const onCtaClick = (e, cta) => {
    if (cta && cta?.text) {
      const btnText = cta?.text.replace(/<[^>]*>?/gm, '').toLowerCase();
      if (t('general.$bookNow').toLowerCase() === btnText) {
        e.preventDefault();
        router.replace(
          {
            query: { ...router.query, book: true },
          },
          undefined,
          { shallow: true },
        );
      }
    }
  };

  return (
    <div {...props}>
      <div className="title-wrapper" role="main" aria-label={t('general.$contentInfo')}>
        {preHeading && (
          <PreHeading
            className={`pre-heading uppercase font-didot text-base font-semibold text-subheading  ${preHeadingClassName}`}
          >
            {preHeading}
          </PreHeading>
        )}
        {headingTitle && <HeadingTag data={headingTitle} className={`${headingClassName} main-title`} />}
        {subHeading && (
          <PreHeading
            className={`pre-heading font-didot text-base font-semibold text-subheading ${subHeadingClassName}`}
          >
            {subHeading}
          </PreHeading>
        )}
      </div>

      {(description || primaryCTA || secondaryCTA) && (
        <div className={`description-wrapper ${descriptionWrapperclassName}`}>
          <div dangerouslySetInnerHTML={{ __html: description }} className={`${descriptionClassName}`}></div>

          {(primaryCTA || secondaryCTA) && (
            <div className="button-wrapper flex flex-col  mt-12 md:mt-14 md:flex-row md:justify-start lg:mt-20">
              {primaryCTA && (
                <Link
                  className={`btn primary first-link md:self-start md:mr-3 color-from-bg  ${
                    secondaryCTA ? 'mb-3' : 'mb-0'
                  }`}
                  link={primaryCTA}
                  onClick={(e) => onCtaClick(e, primaryCTA)}
                >
                  <span>{primaryCTA.text}</span>
                  <ArrowRight className="!ml-6" role="presentation" />
                </Link>
              )}

              {secondaryCTA && (
                <Link
                  className="btn secondary color-from-bg secondary-link md:self-start"
                  link={secondaryCTA}
                  onClick={(e) => onCtaClick(e, secondaryCTA)}
                >
                  <span>{secondaryCTA.text}</span>
                  <ArrowRight className="ml-8" role="presentation" />
                </Link>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

SectionContent.propTypes = {
  data: PropTypes.any.isRequired,
  headingClassName: PropTypes.string,
  preHeadingClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  descriptionWrapperclassName: PropTypes.string,
};

export default SectionContent;
