import { ModuleBase, PreHeading, HeadingTag, Media, Link, ParallaxAnimation } from 'components';
import ArrowRight from 'assets/arrowRight.svg';
import { isColorDark } from 'utils';
import classNames from 'classnames';

const RichTextWithMediaModule = ({ data }) => {
  const { headingTitle, preHeading, media, primaryCTA, mediaAlignment, secondaryCTA, backgroundColour } = data;
  const isMedia = media && (media.videoFromGallery || media.vimeoId || media.coverImage);

  return (
    <ModuleBase data={data}>
      <div className="container color-from-bg">
        <div className="flex flex-col lg:hidden">
          {preHeading && (
            <PreHeading
              className="opacity-50 font-montserrat color-from-bg text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase mb-4
                          md:text-sm md:leading-normal md:tracking-button-reg"
            >
              {preHeading}
            </PreHeading>
          )}
          {headingTitle && (
            <HeadingTag
              data={headingTitle}
              className="font-didot not-italic font-normal 
                        text-title-l leading-10 -tracking-headline-m-mobile
                        sm:text-4xl sm:leading-[44px] sm:-tracking-headline-l-mobile 
                        lg:text-title-xl lg:leading-12 lg:-tracking-headline-s"
            />
          )}
        </div>
        <div
          className={`flex flex-col lg:flex-row w-full lg:gap-16 mt-10 lg:mt-0 ${
            mediaAlignment === 'Right' ? 'lg:flex-row-reverse' : ''
          }`}
        >
          {isMedia && (
            <div
              className={`w-full h-[420px] md:w-[496px] md:h-[737px] xl:w-[600px] xl:h-[837px] mx-auto md:mx-0 mb-10
                ${mediaAlignment === 'Right' ? 'md:ml-auto' : 'md:mr-auto'}`}
            >
              <figure className="w-full h-[420px] md:w-[496px] md:h-[737px] xl:w-[600px] xl:h-[837px]">
                <ParallaxAnimation move={300} zoom>
                  {media && (
                    <Media
                      cover
                      media={media}
                      widths={{ xs: 283, sm: 283, md: 496, lg: 496, xl: 600, xxl: 600 }}
                      heights={{ xs: 420, sm: 420, md: 737, lg: 904, xl: 1003, xxl: 1003 }}
                      className="h-full w-full object-cover "
                    />
                  )}
                </ParallaxAnimation>
                <p className="mt-4 font-montserrat text-xs not-italic font-normal leading-5 opacity-75">
                  {media.coverImage?.imageAlt}
                </p>
              </figure>
              {media?.description && (
                <figcaption className="text-caption mt-4 rtl:text-right">{media.description}</figcaption>
              )}
            </div>
          )}

          <div className={classNames('w-full mt-4 md:mt-4 lg:mt-0', { 'lg:max-w-[560px]': isMedia })}>
            <div className={classNames('hidden lg:flex flex-col', isMedia ? 'lg:mb-[272px]' : 'lg:mb-20')}>
              {preHeading && (
                <PreHeading
                  className="opacity-50 font-montserrat color-from-bg text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase mb-4
                              md:text-sm md:leading-normal md:tracking-button-reg"
                >
                  {preHeading}
                </PreHeading>
              )}
              {headingTitle && (
                <HeadingTag
                  data={headingTitle}
                  className="font-didot not-italic font-normal 
                  text-title-l leading-10 -tracking-headline-m-mobile
                  sm:text-4xl sm:leading-[44px] sm:-tracking-headline-l-mobile 
                  lg:text-title-xl lg:leading-12 lg:-tracking-headline-s"
                />
              )}
            </div>
            <div className="richtext-content mb-10" dangerouslySetInnerHTML={{ __html: data.content }} />
            <div className="flex flex-col items-start">
              {primaryCTA && (
                <Link
                  className={`btn primary color-from-bg w-full md:w-fit ${secondaryCTA ? 'mb-4' : 'mb-0'}`}
                  link={primaryCTA}
                >
                  <span>{primaryCTA.text}</span>
                  <ArrowRight className="!ml-6 color-from-bg" role="presentation" />
                </Link>
              )}
              {secondaryCTA && (
                <Link
                  className={`btn secondary border-solid text-brand-soft-white [&_svg]:stroke-white secondary-link w-full md:w-fit !bg-background-spotlight
                            ${
                              isColorDark(backgroundColour)
                                ? '!border-solid !border-brand-soft-white !border-opacity-25'
                                : '!border-transparent'
                            }
                  `}
                  link={secondaryCTA}
                >
                  <span>{secondaryCTA.text}</span>
                  <ArrowRight className="ml-8" role="presentation" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};
export default RichTextWithMediaModule;
