import React from 'react';

const RadioGroupInput = ({ options, name, onChange, ...props }) => {
  const handleOptionChange = (value) => {
    if (onChange) {
      onChange({ target: { name, value } });
    }
  };

  return (
    <div className="radio-group-container">
      {options.map((option, index) => (
        <label key={name + '-' + index} className="radio-group" onClick={() => handleOptionChange(option.value)}>
          <input
            type="radio"
            className="input"
            id={option.value.toLowerCase()}
            name={name}
            value={option.value}
            defaultChecked={props.value === option.value}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default RadioGroupInput;
