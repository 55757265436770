import { HeadingTag, Link, PreHeading } from '@/components';
import { ModuleBase } from 'components';
import ArrowRight from 'assets/arrowRight.svg';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

const HeadingVerticalModule = ({ data, className }) => {
  const { t } = useTranslation('common');
  const { alignment } = data;
  const lowerCaseAlignment = alignment?.toLowerCase() || '';
  const moduleAligment =
    lowerCaseAlignment === 'center' || lowerCaseAlignment === ''
      ? 'flex-col text-center xl:px-16'
      : 'flex-col lg:flex-row text-left justify-between';

  return (
    <ModuleBase data={data}>
      <div
        className={classNames('container pt-6 pb-4 md:pb-6 lg:pt-10 lg:pb-4  flex', className, moduleAligment)}
        role="contentinfo"
        aria-label={t('general.$contentInfo')}
      >
        <div className={classNames('title-wrapper pr-4')}>
          {data.preHeading && (
            <PreHeading className="pre-heading uppercase font-didot text-base font-semibold text-subheading">
              {data.preHeading}
            </PreHeading>
          )}
          {data.headingTitle && (
            <HeadingTag
              data={data.headingTitle}
              className={classNames(
                'main-title font-didot text-title-l leading-9 -tracking-headline-m-mobile md:-tracking-headline-m-tablet lg:-tracking-headline-l-tablet color-from-bg font-normal mb-10  md:text-5xl md:leading-12  lg:text-headline-m lg:leading-[53px] whitespace-pre-wrap',
                lowerCaseAlignment === 'left' ? 'md:pl-0' : 'md:mx-10',
              )}
            />
          )}
          {data.subHeading && (
            <PreHeading className="pre-heading font-didot text-base font-semibold text-subheading">
              {data.subHeading}
            </PreHeading>
          )}
        </div>

        {(data.description || data.primaryCTA || data.secondaryCTA) && (
          <div className="description-wrapper ">
            <div
              dangerouslySetInnerHTML={{ __html: data.description }}
              className={classNames(
                'font-normal color-from-bg w-full !text-opacity-75 font-montserrat text-sm lg:text-base leading-6 md:leading-6 md:px-10 lg:px-0 lg:mx-auto lg:leading-6.5',
                lowerCaseAlignment === 'left' ? 'md:pl-0 lg:w-[704px]' : 'md:mx-10 lg:w-[830px] ',
              )}
            ></div>

            {(data.primaryCTA || data.secondaryCTA) && (
              <div
                className={classNames(
                  'button-wrapper flex flex-col md:flex-row   gap-4',
                  lowerCaseAlignment === 'center' || lowerCaseAlignment === ''
                    ? 'md:justify-center mt-14 md:mt-16 lg:mt-[72px]'
                    : 'md:justify-start mt-8 md:mt-12',
                )}
              >
                {data.primaryCTA && (
                  <Link className="btn primary first-link color-from-bg" link={data.primaryCTA}>
                    <span>{data.primaryCTA.text}</span>
                    <ArrowRight className="!ml-6" role="presentation" />
                  </Link>
                )}

                {data.secondaryCTA && (
                  <Link className="btn primary secondary-link color-from-bg" link={data.secondaryCTA}>
                    <span>{data.secondaryCTA.text}</span>
                    <ArrowRight className="!ml-6" role="presentation" />
                  </Link>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </ModuleBase>
  );
};

export default HeadingVerticalModule;
