import PropTypes from 'prop-types';
import { Link, ResponsiveImage } from '..';

const ResortCard = ({ data }) => {
  return (
    <Link
      link={data.primaryCta}
      className="article-card text-brand-soft-white w-full h-[468px] md:h-[516px] lg:h-[612px]"
    >
      <div className="relative w-full h-full">
        {data.image && (
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 258, md: 352, lg: 424 }}
            heights={{ xs: 468, md: 516, lg: 612 }}
            className="h-full w-full object-cover"
            image-class="h-full w-full object-cover"
          />
        )}

        <div className="content-wrapper gradient-bottom-to-top absolute left-0 top-0 w-full h-full font-didot flex flex-col justify-between grow p-6">
          <div className="category-wrapper w-fit bg-black/25 px-5 py-2 rounded-full font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase">
            <span className="font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase">
              {data.preHeading}
            </span>
          </div>
          <div className="text-wrapper">
            {data.headingTitle && (
              <p
                className="font-didot text-2xl italic font-normal leading-normal -tracking-card-s mb-4 lg:text-title-m lg:-tracking-card-m"
                role="heading"
                aria-level="7"
              >
                {data.headingTitle}
              </p>
            )}

            {data.description && (
              <div
                dangerouslySetInnerHTML={{ __html: data.description }}
                className="font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase"
              ></div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

ResortCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ResortCard;
