import PropTypes from 'prop-types';
import { ResponsiveImage, PreHeading, Link } from '..';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import ArrowRight from 'assets/arrowRight.svg';

// eslint-disable-next-line no-unused-vars
const GenericCardModel = ({ data, className, dark, numberOfColumns, loading, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <div
      className={classNames(
        'main-wrapper generic-card flex flex-col w-full',
        className,
        data.isDarkTheme ? 'bg-background-spotlight' : 'bg-background-card',
      )}
      {...props}
    >
      <div className="img-wrapper aspect-[332/250] lg:aspect-[600/420] w-full max-h-[250px] lg:max-h-full">
        {data.image && (
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 498, md: 498, lg: 600 }}
            heights={{ xs: 375, md: 375, lg: 420 }}
            className="h-full w-full object-cover"
            image-class="h-full w-full object-cover"
          />
        )}
      </div>
      <div
        className={classNames(
          'content-wrapper font-didot mx-4 mt-6 mb-4 md:my-6 lg:mx-5 flex flex-col justify-between grow',
          data.isDarkTheme ? 'text-brand-soft-white' : 'text-brand-black',
        )}
      >
        <div className="text-wrapper">
          {data.preHeading && (
            <PreHeading
              className="font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase
                          lg:tracking-button-reg"
            >
              {data.preHeading}
            </PreHeading>
          )}

          {data.headingTitle && (
            <div
              className="mt-4 mb-3 font-didot text-xl italic font-normal leading-normal -tracking-card-xs
                        md:text-2xl md:-tracking-card-s
                        lg:text-title-m lg:-tracking-card-m"
              dangerouslySetInnerHTML={{ __html: data.headingTitle }}
              role="heading"
              aria-level="7"
            ></div>
          )}

          {data.description && (
            <div
              className={classNames(
                'font-montserrat text-sm not-italic font-normal leading-6',
                data.isDarkTheme ? 'opacity-90' : 'opacity-75',
              )}
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></div>
          )}
        </div>
        {data.primaryCta && (
          <div className="button-wrapper mt-8">
            <Link
              className={classNames(
                'btn w-full md:w-fit border-opacity-25 group',
                data.isDarkTheme ? 'primary-light border-brand-soft-white' : 'primary border-brand-black',
              )}
              link={data.primaryCta}
            >
              <span>{t('general.$viewMore')}</span>
              <ArrowRight
                className={classNames(
                  '!ml-6 !stroke-none',
                  data.isDarkTheme ? '!fill-brand-soft-white' : '!fill-brand-black group-hover:!fill-brand-soft-white',
                )}
                role="presentation"
              />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

GenericCardModel.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GenericCardModel;
