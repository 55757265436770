import { useTranslation } from 'next-i18next';

const FormTitleAndDescription = ({
  settings = { captionTag: '', caption: '', bodyText: '' },
  formDefinitionName,
  name,
  ...props
}) => {
  const { captionTag, caption, bodyText } = settings;
  const CaptionTag = captionTag;
  const { t } = useTranslation('common');

  return (
    <section className="title-and-description" {...props}>
      {captionTag && <CaptionTag className="form-title">{caption}</CaptionTag>}
      <p className="text-sm not-italic font-normal leading-6 tracking-[-0.14px] font-montserrat text-black/50">
        {bodyText ||
          t(`forms.${formDefinitionName}.$${formDefinitionName}${name.charAt(0).toUpperCase() + name.slice(1)}`)}
      </p>
    </section>
  );
};

export default FormTitleAndDescription;
