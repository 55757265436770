import React, { useState } from 'react';
import { ModuleBase, PreHeading, HeadingTag, Link } from 'components';
import ArrowForward from 'assets/arrowForward.svg';
import ArrowRight from 'assets/arrowRight.svg';
import classnames from 'classnames';
import { colorFromBgColor } from '@/utils';

const AccordionModule = ({ data }) => {
  const [activeIndexList, setActiveIndexList] = useState([]);

  const toggle = (index) => {
    let newArray = [...activeIndexList];
    if (newArray.includes(index)) {
      newArray = newArray.filter((i) => i !== index);
    } else {
      if (data.allowOpeningMultiple) {
        newArray = [index, ...newArray];
      } else {
        newArray = [index];
      }
    }
    setActiveIndexList(newArray);
  };

  return (
    <ModuleBase data={data}>
      <div className="container lg:py-4 color-from-bg">
        <div className="title-wrapper">
          {data.preHeading && (
            <PreHeading className="uppercase font-montserrat font-medium text-xs tracking-title-m leading-normal">
              {data.preHeading}
            </PreHeading>
          )}
          {data.headingTitle && (
            <HeadingTag
              data={data.headingTitle}
              className="font-montserrat mt-4 color-from-bg uppercase text-base leading-5 tracking-headline-m-tablet font-semibold md:text-xl md:leading-6 md:tracking-title-m"
            />
          )}
        </div>
        {data.accordionElements && (
          <div className="my-10 lg:my-12">
            {data.accordionElements.map((element, index) => (
              <div
                key={index}
                className="border-b color-from-bg border-opacity-10 py-4 first:pt-0 md:px-2 first:md:pt-4"
              >
                <button
                  type="button"
                  className="flex items-center justify-between w-full text-left font-montserrat text-base leading-6.5"
                  onClick={() => toggle(index)}
                >
                  <span
                    className={classnames(
                      activeIndexList.includes(index) ? 'font-semibold' : 'font-normal delay-300',
                      'transition-all duration-100',
                    )}
                  >
                    {element.title}
                  </span>
                  <div className={`stroke-['${colorFromBgColor(data.backgroundColour)}']`}>
                    <ArrowForward
                      role="presentation"
                      className={classnames(
                        'scale-50 transition-transform duration-200 !stroke-current',
                        activeIndexList.includes(index) ? '-rotate-90' : ' rotate-90',
                      )}
                    />
                  </div>
                </button>

                <div
                  className={classnames(
                    'transition-all overflow-hidden duration-500',
                    activeIndexList.includes(index)
                      ? `max-h-screen pt-3 ${!data.allowOpeningMultiple ? 'delay-500' : ''}`
                      : 'max-h-0 pt-0',
                  )}
                >
                  {element.text && (
                    <div
                      dangerouslySetInnerHTML={{ __html: element.text }}
                      className="font-montserrat text-sm leading-6 font-normal max-w-[1049px]"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {data.primaryCTA && (
          <div className="flex md:justify-start lg:justify-end ">
            <Link className="btn primary w-full color-from-bg md:w-fit" link={data.primaryCTA}>
              <span>{data.primaryCTA.text}</span>
              <ArrowRight role="presentation" className="!ml-6" />
            </Link>
          </div>
        )}
      </div>
    </ModuleBase>
  );
};

export default AccordionModule;
