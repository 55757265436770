import classNames from 'classnames';
import NavItem from './NavItem';
import { Link, LanguageSelect } from 'components';
import { useTranslation } from 'next-i18next';
import NavArrow from 'assets/navArrow.svg';

const NavColumn = ({
  navItems,
  detailedNavItems,
  level,
  activeMenu,
  setActiveMenu,
  close,
  cta,
  ctaClass,
  parentMenuName,
  addQueryParam,
  metadata,
  showHomeLink,
}) => {
  const lKey = `l${level}`;
  const _setActiveMenu = (index, subMenu) => {
    let newValue = {};
    newValue[`m${index}`] = subMenu;
    setActiveMenu(newValue);
  };
  const { t } = useTranslation('common');

  return (
    <div
      className={classNames(
        'absolute top-0 w-full h-full transition-transform duration-700 ease-out origin-right overflow-hidden pointer-events-none',
        !(activeMenu && activeMenu[lKey]) && 'translate-x-full lg:-translate-x-full',
        level ? 'left-0 lg:left-1/3' : 'left-0',
        { 'z-0': level === 0, 'z-10 lg:-z-10': level === 1, 'z-20 lg:-z-20': level === 2 },
      )}
    >
      <div className="w-full h-full relative pointer-events-none">
        <div
          className={classNames(
            'lg:w-1/3 h-full pt-[90px] pb-[81px] lg:pb-0 lg:pt-[116px] pointer-events-auto bg-brand-soft-white',
            {
              'lg:bg-brand-soft-white': level === 0,
              'lg:bg-background-primary': level === 1,
              'lg:bg-background-spotlight': level === 2,
            },
          )}
        >
          {parentMenuName && (
            <button
              className="pl-8 sm:pl-10 lg:pl-16 pt-10 lg:hidden cursor-pointer text-black flex items-center py-3 text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase font-montserrat"
              onClick={() => setActiveMenu(null)}
            >
              <NavArrow className="-rotate-180 fill-black mr-2" role="presentation" />
              <span className="-mt-px">{t('general.$back')}</span>
            </button>
          )}
          <div
            className={classNames(
              'overflow-y-auto flex flex-col lg:gap-4 h-full max-h-full lg:pt-7 pl-8 sm:pl-10 lg:pl-16 pr-8 sm:pr-10 lg:pr-14',
              !parentMenuName && 'pt-7',
              level !== 0 && 'pb-20 lg:pb-0',
            )}
          >
            {showHomeLink && (
              <Link
                className="hidden lg:flex py-3 lg:py-2 font-montserrat text-base not-italic font-normal leading-6.5 cursor-pointer  items-center justify-between duration-500 [&_svg]:duration-500 text-black hover:text-black"
                link={{ href: '/', text: t('general.$maxxRoyalHome') }}
                onClick={close}
              />
            )}
            {}
            {navItems?.map((nav, index) => (
              <NavItem
                navItem={nav}
                key={index}
                level={level}
                activeMenu={activeMenu ? (activeMenu[lKey] ? activeMenu[lKey][`m${index}`] : {}) : {}}
                setActiveMenu={(subMenu) => _setActiveMenu(index, subMenu)}
                close={close}
                parentMenuName={parentMenuName}
                dividerTop={level === 0 && navItems.length - 1 == index}
              />
            ))}

            {detailedNavItems?.map((nav, index) => (
              <NavItem
                navItem={nav}
                key={index}
                level={level}
                activeMenu={activeMenu ? (activeMenu[lKey] ? activeMenu[lKey][`m${index}`] : {}) : {}}
                setActiveMenu={(subMenu) => _setActiveMenu(index, subMenu)}
                close={close}
                parentMenuName={parentMenuName}
                dividerTop={true}
                dividerBottom={true}
              />
            ))}

            {level === 0 && (
              <button
                className="hidden lg:flex py-3 lg:py-2 font-montserrat text-base not-italic font-normal leading-6.5 cursor-pointer  items-center justify-between duration-500 [&_svg]:duration-500 text-black hover:text-black"
                onClick={() => addQueryParam({ contact: true })}
              >
                {t('general.$contact')}
              </button>
            )}

            {level === 0 && (
              <div className="w-max lg:hidden">
                <LanguageSelect
                  metadata={metadata}
                  className="[&_.main-button]:px-0 [&_.main-button_svg]:fill-black"
                  onChange={close}
                />
              </div>
            )}

            {cta && (
              <Link
                className={classNames(ctaClass, level === 2 ? ' lg:text-brand-soft-white' : 'lg:text-black')}
                link={cta}
                onClick={close}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavColumn;
