import React from 'react';
import PropTypes from 'prop-types';
import { ModuleBase, EntityCard, HeadingTag, Link } from 'components';
import ArrowRight from 'assets/arrowRight.svg';
import { useTranslation } from 'next-i18next';
import { isColorDark } from '@/utils';
import classNames from 'classnames';

const CardListIemsModule = ({ data }) => {
  const { cards, headingTitle, description, primaryCTA, numberOfColumns } = data || {};
  const { t } = useTranslation('common');

  const dark = isColorDark(data.backgroundColour);

  return (
    <ModuleBase data={data} className="overflow-hidden pb-10 pt-20 md:pt-20 lg:pb-20 ">
      <div className="container">
        <div
          className="description-data w-full mb-6 lg:mb-4 lg:flex lg:justify-between"
          role="contentinfo"
          aria-label={t('general.$contactInfo')}
        >
          <div className="title-wrapper lg:max-w-[808px]">
            {headingTitle && (
              <HeadingTag
                data={headingTitle}
                className="main-title color-from-bg font-montserrat mb-4 text-base not-italic font-semibold leading-5 tracking-headline-m-tablet uppercase
                           md:text-xl md:leading-6 md:tracking-quote-reg-mobile"
              />
            )}
            {description && (
              <div
                dangerouslySetInnerHTML={{ __html: description }}
                className="font-montserrat color-from-bg text-sm not-italic font-normal leading-6 lg:text-base lg:leading-6.5"
              ></div>
            )}
          </div>

          {primaryCTA && (
            <Link className="btn primary first-link md:self-start color-from-bg mt-8" link={primaryCTA}>
              <span>{primaryCTA.text}</span>
              <ArrowRight className="ml-8" role="presentation" />
            </Link>
          )}
        </div>

        {cards.map((cardItem, cIndex) => (
          <div
            key={cIndex}
            className={classNames(
              'flex flex-col gap-8 md:gap-x-6 md:gap-y-10 md:grid ${columnLayout()} mt-8 md:mt-10',
              {
                'md:grid-cols-2': numberOfColumns.toLowerCase() === 'two',
                'md:grid-cols-2 lg:grid-cols-3': numberOfColumns.toLowerCase() === 'three',
              },
            )}
          >
            {cardItem.props.cards.map((card, cardIndex) => (
              <div key={cardIndex} className="card-wrapper flex">
                <EntityCard
                  data={card}
                  className={`w-full ${
                    (card.moduleName === 'ExperienceCard' || card.moduleName === 'GenericCard') &&
                    numberOfColumns === 'Three'
                      ? '[&_.img-wrapper]:lg:aspect-[392/319] [&_.img-wrapper]:lg:max-h-[319px]'
                      : ''
                  }`}
                  numberOfColumns={numberOfColumns}
                  dark={dark}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </ModuleBase>
  );
};

CardListIemsModule.propTypes = {
  data: PropTypes.any.isRequired,
  buttonWrapperClassName: PropTypes.string,
};

export default CardListIemsModule;
