import React, { useRef, useState } from 'react';
import { ModuleBase, PreHeading } from 'components';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import Arrow from 'assets/arrow.svg';
import { ResponsiveImage, HeadingTag, Link } from '@/components';
import Instagram from 'assets/insta2.svg';
import { useTranslation } from 'next-i18next';
import CarouselPagination from '@/components/general/CarouselPagination';
import { fixColor, isColorDark } from '@/utils/color-utils.js';
import { useSelector } from 'react-redux';
import { selectIsRobot } from 'store/index';

const SocialMediaCarousel = ({ data }) => {
  const carousel = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [panelCount, setPanelCount] = useState(0);
  const { t } = useTranslation('common');
  const dark = isColorDark(fixColor(data?.backgroundColour));
  const isRobot = useSelector(selectIsRobot);

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  const onReady = () => {
    setPanelCount(carousel.current.panelCount);
  };

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };
  return (
    <ModuleBase data={data} className="relative color-from-bg">
      <div className="relative container pr-0 xxl:pr-[108px]">
        <div className="text-wrapper relative z-20 h-full lg:flex md:gap-6 md:justify-between pr-6 md:pr-10 lg:pr-[108] mb-16 lg:mb-14">
          <div className="title-and-description z-20 mb-8 lg:mb-0 lg:w-[808px]">
            {data.label && (
              <PreHeading className="font-montserrat color-from-bg text-sm not-italic font-medium leading-normal tracking-button-reg uppercase mb-4 opacity-50">
                {data.label}
              </PreHeading>
            )}
            {data.heading && (
              <HeadingTag
                data={{
                  heading: data.heading,
                  htag: '',
                }}
                className="main-title color-from-bg mb-4 font-montserrat text-base not-italic font-semibold leading-5 tracking-headline-m-tablet uppercase
                            md:text-xl md:leading-6 md:tracking-quote-reg-mobile"
              />
            )}

            {data.text && (
              <div
                dangerouslySetInnerHTML={{ __html: data.text }}
                className="font-montserrat color-from-bg text-sm not-italic font-normal leading-6 lg:text-base lg:leading-6.5"
              ></div>
            )}
          </div>

          <div className="link-wrapper z-20 flex flex-col items-end justify-end">
            {data.primaryCTA && (
              <Link
                className="btn primary first-link md:self-start md:mr-3 color-from-bg w-full 
                              text-sm not-italic font-medium leading-5 tracking-button-reg uppercase md:w-fit"
                link={data.primaryCTA}
              >
                <span>{data.primaryCTA.text}</span>
                <Instagram role="presentation" className={`!ml-7 ${dark ? 'fill-white' : 'fill-black'}`} />
              </Link>
            )}
          </div>
        </div>

        <Flicking
          ref={carousel}
          align="prev"
          useResizeObserver={true}
          horizontal={true}
          circular={true}
          onWillChange={willChange}
          onReady={onReady}
          className="pb-16"
        >
          {data.images.map((slide, index) => (
            <div key={index} className="w-[288px] aspect-square mr-4">
              <ResponsiveImage
                image={slide}
                widths={{ xs: 288, sm: 288, md: 288, lg: 288, xl: 288, xxl: 288 }}
                heights={{ xs: 288, sm: 288, md: 288, lg: 288, xl: 288, xxl: 288 }}
                className="h-full w-full object-cover"
                dataNotLazy={!isRobot}
              />
            </div>
          ))}
        </Flicking>

        <CarouselPagination
          dark={dark}
          current={currentIndex}
          total={panelCount}
          className="absolute left-1/2 translate-x-[calc(-50%_-_12px)]  lg:-translate-x-0 lg:left-[unset] lg:right-[108px] xxl:right-[216px]"
          moduleName={data.moduleName}
        />

        <div className="hidden lg:flex gap-3 justify-end -mt-10 absolute lg:!right-[168px] xxl:!right-[276px] bottom-[54px]">
          <button
            className={`btn text-light  border-none circle bg-brand-green p-0 w-10 h-10 sm:w-12 sm:h-12 z-10 ${
              currentIndex === 0
                ? 'opacity-0 transition-all duration-1000 pointer-events-none'
                : 'opacity-1 transition-all duration-1000'
            }`}
            onClick={move}
            aria-label={t('general.$previous')}
          >
            <Arrow className="rotate-180" />
          </button>

          <button
            className={`btn text-light border-none circle bg-brand-green p-0 w-10 h-10 sm:w-12 sm:h-12 z-10 ${
              currentIndex === data.images.length - 1
                ? 'opacity-0 transition-all duration-1000 pointer-events-none'
                : 'opacity-1 transition-all duration-1000'
            }`}
            onClick={() => move(1)}
            aria-label={t('general.$next')}
          >
            <Arrow />
          </button>
        </div>
      </div>
    </ModuleBase>
  );
};
export default SocialMediaCarousel;
