import React, { useRef, useState } from 'react';
import { ModuleBase, ResponsiveImage, Media, ParallaxAnimation } from 'components';
import SectionContent from 'components/cards/SectionContent.jsx';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import CarouselPagination from '@/components/general/CarouselPagination';

const RichTextAndTwoMediaModule = ({ data }) => {
  const carousel = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [panelCount, setPanelCount] = useState(0);

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  const onReady = () => {
    setPanelCount(carousel.current.panelCount);
  };

  return (
    <ModuleBase data={data}>
      <div
        className={`container main-data flex pb-0 color-from-bg  flex-col-reverse md:flex-col lg:justify-between ${
          data.mediaAlignment === 'Left' ? ' lg:flex-col' : ' lg:flex-col'
        } `}
      >
        <div className="text-wrapper md:hidden md:w-[70%] lg:my-auto lg:w-[496px]">
          <SectionContent
            data={data}
            preHeadingClassName="font-montserrat text-xs not-italic !font-medium leading-normal tracking-quote-reg-mobile uppercase mb-6"
            headingClassName="font-montserrat text-base not-italic font-semibold leading-5 tracking-headline-m-tablet uppercase"
            descriptionClassName="font-montserrat text-sm not-italic font-normal leading-6"
            className="[&_.title]:mt-0 [&_.description-wrapper]:mt-6 [&_.button-wrapper]:mt-14"
          />
        </div>

        <div className="relative">
          <Flicking
            className="block md:hidden mb-8"
            ref={carousel}
            align="center"
            useResizeObserver={true}
            horizontal={true}
            circular={true}
            onWillChange={willChange}
            onReady={onReady}
          >
            <div className="w-full aspect-3/4">
              <ResponsiveImage
                image={data.largeMedia}
                widths={{ xs: 500, sm: 600, md: 332, lg: 392, xl: 392, xxl: 392 }}
                heights={{ xs: 667, sm: 480, md: 442, lg: 522, xl: 522, xxl: 522 }}
                className="absolute h-full w-full object-cover"
              />
            </div>

            <div className="w-full aspect-3/4">
              <Media
                cover
                media={data.smallMedia}
                widths={{ xs: 500, sm: 600, md: 332, lg: 392, xl: 392, xxl: 392 }}
                heights={{ xs: 667, sm: 480, md: 442, lg: 522, xl: 522, xxl: 522 }}
                className="h-full w-full object-cover pointer-events-auto"
              />
            </div>
          </Flicking>

          <CarouselPagination
            dark={true}
            current={currentIndex}
            total={panelCount}
            className="block md:hidden absolute bottom-14 z-20 left-1/2 -translate-x-1/2"
            moduleName={data.moduleName}
          />
        </div>

        {/* tablet and desktop */}
        <div
          className={`text-wrapper hidden md:flex md:w-full md:mb-14 lg:mb-6   
          ${data.mediaAlignment === 'Left' ? ' ml-auto lg:w-[496px]' : ' mr-auto lg:w-[583px]'}
         `}
        >
          <SectionContent
            data={data}
            preHeadingClassName="font-montserrat md:mb-8 md:text-xs md:not-italic md:font-medium md:leading-normal md:tracking-quote-reg-mobile md:uppercase"
            headingClassName="font-montserrat md:text-xl md:font-semibold md:leading-6 md:tracking-quote-reg-mobile md:uppercase"
            descriptionClassName="font-montserrat text-sm not-italic font-normal leading-6 md:text-base md:leading-6.5 lg:text-base lg:font-normal lg:leading-6.5"
            className={`[&_.title]:mt-0 [&_.description-wrapper]:mt-8 [&_.button-wrapper]:mt-14 md:[&_.first-link]:mb-0 
             ${data.mediaAlignment === 'Left' ? '[&_.first-link]:ml-auto' : '[&_.first-link]:ml-0'}
            `}
          />
        </div>

        <div
          className={`img-wrapper hidden md:flex md:my-auto md:justify-between md:h-fit md:w-full ${
            data.mediaAlignment === 'Left' ? 'md:flex-row' : 'md:flex-row-reverse'
          }`}
        >
          <div
            className={`main-img md:relative md:aspect-3/4
                xl:h-[90vh] xl:my-auto lg:h-[80vh] lg:w-[48%] xl:w-auto
                md:minheight800:w-[337px] lg:minheight800:w-[48%] md:minheight800:h-[449px] lg:minheight800:h-auto xl:minheight800:w-[600px]
          
          ${data.mediaAlignment === 'Left' ? 'lg:mr-10' : 'lg:ml-10'}
          `}
          >
            <ParallaxAnimation move={300} className="md:[&_.inner-img-wrapper]:h-full lg:my-auto">
              <ResponsiveImage
                image={data.largeMedia}
                widths={{ xs: 506, sm: 506, md: 500, lg: 500, xl: 650, xxl: 650 }}
                heights={{ xs: 674, sm: 674, md: 666, lg: 854, xl: 1047, xxl: 1047 }}
                className="absolute h-full w-full object-cover"
              />
            </ParallaxAnimation>
          </div>

          <div
            className="secondary-img-wrapper md:mt-[72px] md:mb-[54px] lg:mt-32 lg:mb-[95px] md:aspect-[278/323]  
                xl:h-[75vh] md:w-[35%] xl:w-auto
                md:minheight800:w-[278px] md:minheight800:h-[324px] lg:minheight800:h-auto lg:minheight800:w-[35%] xl:minheight800:w-[496px] xl:minheight800:aspect-[496/576]"
          >
            <ParallaxAnimation move={300}>
              <Media
                cover
                media={data.smallMedia}
                widths={{ xs: 417, sm: 417, md: 417, lg: 744, xl: 744, xxl: 744 }}
                heights={{ xs: 485, sm: 485, md: 485, lg: 864, xl: 864, xxl: 864 }}
                className="h-full w-full object-cover md:aspect-[278/323]"
              />
            </ParallaxAnimation>
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};

export default RichTextAndTwoMediaModule;
