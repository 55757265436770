import { Link, ResponsiveImage } from 'components';
import ResortNavColumn from './ResortNavColumn';
import NavArrow from 'assets/navArrow.svg';

const ResortNavItem = ({ navItem, level, activeMenu, setActiveMenu, close, moduleName }) => {
  const _setActiveMenu = (sub) => {
    let newValue = {};
    newValue[`l${level + 1}`] = sub;
    setActiveMenu(newValue);
  };

  const linkClass =
    'py-3 lg:py-2 font-montserrat text-base not-italic font-normal leading-6.5 cursor-pointer flex items-center justify-between text-black [&_svg]:fill-black';

  return (
    <>
      {moduleName === 'VisualizedNavItemModel' ? (
        <div className="flex w-full gap-10 border-t first:border-t-0 border-t-opacity-black/10 sm:border-t-0 pt-6 first:pt-0 sm:pt-0">
          <Link
            link={navItem.props.link}
            key={navItem.moduleId}
            className="w-full font-montserrat flex sm:flex-col gap-4 sm:gap-6"
            onClick={close}
          >
            <div className="min-w-[108px] sm:h-[146px]">
              <ResponsiveImage
                image={navItem.props.image}
                widths={{ xs: 150, sm: 400 }}
                heights={{ xs: 150, sm: 200 }}
              />
            </div>
            <div className="">
              <div className="text-sm not-italic font-medium leading-normal tracking-button-reg uppercase">
                {navItem.props.title}
              </div>
              <p className="mt-1 text-xs not-italic font-normal leading-5">{navItem.props.description}</p>
            </div>
          </Link>
        </div>
      ) : (
        <>
          <div>
            {navItem.props?.flyOut?.length > 0 ? (
              <div className={linkClass} onClick={() => _setActiveMenu({})}>
                {navItem.props.navigationItemTitle || navItem.props.title}
                {navItem.props.flyOut?.length > 0 && <NavArrow role="presentation" />}
              </div>
            ) : (
              <Link
                className={linkClass}
                link={{
                  ...(navItem.props.navigationItemLink || navItem.props.link || { href: '/' }),
                  text: navItem.props.navigationItemTitle || navItem.props.title,
                }}
                onClick={close}
              />
            )}
          </div>
          {navItem.props?.flyOut?.length > 0 && (
            <ResortNavColumn
              navItems={navItem.props.flyOut}
              level={level + 1}
              activeMenu={activeMenu}
              setActiveMenu={(sub) => _setActiveMenu(sub)}
              close={close}
              cta={navItem.props.highlightedLink}
              ctaClass={linkClass}
            />
          )}
        </>
      )}
    </>
  );
};

export default ResortNavItem;
