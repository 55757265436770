import React, { useRef, useState } from 'react';
import { ModuleBase } from 'components';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import CarouselPagination from '@/components/general/CarouselPagination';
import '@egjs/react-flicking/dist/flicking.css';
import Arrow from 'assets/arrow.svg';
import QuoteSectionContent from '@/components/cards/QuoteSectionContent';
import { useTranslation } from 'next-i18next';
import { fixColor, isColorDark } from '@/utils/color-utils.js';

const QuoteCarouselModule = ({ data }) => {
  const carousel = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [panelCount, setPanelCount] = useState(0);
  const { t } = useTranslation('common');
  const dark = isColorDark(fixColor(data?.backgroundColour));

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  const onReady = () => {
    setPanelCount(carousel.current.panelCount);
  };

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  return (
    <ModuleBase data={data} className="relative color-from-bg ">
      <div
        className={`container relative py-2 md:py-0 lg:pt-20 lg:pb-4 lg:px-[170px] ${
          data.quotes.length === 1 ? 'pointer-events-none' : 'pointer-events-auto'
        }`}
      >
        <Flicking
          ref={carousel}
          align="center"
          useResizeObserver={true}
          horizontal={true}
          circular={false}
          onWillChange={willChange}
          onReady={onReady}
        >
          {data.quotes.map((slide, index) => (
            <div key={index} className="quote-wrapper w-full h-full mx-10 mb-20 lg:mb-12">
              <QuoteSectionContent
                data={{ ...slide, backgroundColour: data.backgroundColour }}
                className="select-none"
              />
            </div>
          ))}
        </Flicking>

        <CarouselPagination
          dark={dark}
          current={currentIndex}
          total={panelCount}
          className="mx-auto lg:mx-0 lg:ml-auto"
          moduleName={data.moduleName}
        />

        <div className="hidden lg:flex gap-3 justify-end -mt-10 absolute right-[108px] bottom-[98px]">
          <button
            className={`btn text-light  border-none circle bg-brand-green p-0 w-10 h-10 sm:w-12 sm:h-12 z-10 ${
              currentIndex === 0
                ? 'opacity-0 transition-all duration-1000 pointer-events-none'
                : 'opacity-1 transition-all duration-1000'
            }`}
            onClick={move}
            aria-label={t('general.$previous')}
          >
            <Arrow className="rotate-180" />
          </button>

          <button
            className={`btn text-light border-none circle bg-brand-green p-0 w-10 h-10 sm:w-12 sm:h-12 z-10 ${
              currentIndex === data.quotes.length - 1
                ? 'opacity-0 transition-all duration-1000 pointer-events-none'
                : 'opacity-1 transition-all duration-1000'
            }`}
            onClick={() => move(1)}
            aria-label={t('general.$next')}
          >
            <Arrow />
          </button>
        </div>
      </div>
    </ModuleBase>
  );
};
export default QuoteCarouselModule;
