import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ModalPortal } from '@/components';
import classNames from 'classnames';
import AvailabilityForm from './AvailabilityForm';
import Close from 'assets/close.svg';
import MaxxLogo from 'assets/maxx_logo.svg';

const BookingDropdown = ({ availabilityData }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');
  const router = useRouter();

  useEffect(() => {
    if (router.query.book) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.book]);

  const onClose = () => {
    const query = { ...router.query };
    delete query.book;
    router.replace(
      {
        query,
      },
      undefined,
      { shallow: true },
    );
    setOpen(false);
  };

  return (
    <ModalPortal>
      <div
        className={classNames(
          'fixed top-0 left-0 z-40 w-screen backdrop-blur transition-backdrop-filter duration-500',
          open ? 'h-dvh lg:h-screen disable-scroll' : 'h-0',
        )}
      />
      <div
        className={classNames(
          'bg-black fixed top-0 left-0 z-40 w-screen transition-opacity duration-500',
          open ? 'h-dvh lg:h-screen opacity-75' : 'h-0 opacity-0',
        )}
      />

      <div
        className={classNames(
          ' fixed top-0 left-0 z-50 w-screen h-dvh lg:h-screen transition-transform duration-500 overflow-hidden',
          { '-translate-y-full': !open },
        )}
      >
        <div className="relative h-full">
          <div className="bg-background-spotlight absolute top-0 w-screen h-dvh lg:h-[calc(100vh-141px)] lg:min-h-[630px]" />

          <div className="flex flex-col h-full relative">
            <div className="w-full flex justify-between lg:justify-end items-center px-6 sm:px-10 lg:px-16 py-6 gap-4 border-b-brand-soft-white/25 border-b">
              <div className="text-background-card font-montserrat text-sm not-italic font-medium leading-5 tracking-button-reg uppercase">
                {t('general.$close')}
              </div>
              <button
                onClick={onClose}
                className="btn p-1.5 h-auto bg-transparent border border-brand-soft-white/25 [&_svg]:stroke-brand-soft-white
                hover:text-black hover:bg-brand-soft-white  hover:border-brand-soft-white [&_svg]:hover:stroke-black"
                aria-label={t('general.$close')}
              >
                <Close className="w-6 h-6 lg:w-8 lg:h-8" />
              </button>
            </div>

            <div className="overflow-y-auto relative h-full">
              <div className="py-10 sm:py-14 lg:py-[72px] container h-full lg:h-auto mb-10 lg:mb-0">
                <div className="flex flex-col justify-center items-center h-full lg:h-auto">
                  <div>
                    <MaxxLogo className="fill-brand-soft-white w-[200px] h-[43px]" role="presentation" />
                  </div>
                  <div
                    className="mt-14 text-brand-soft-white text-center font-didot italic font-normal 
                  text-title-l leading-10 -tracking-headline-m-mobile
                  lg:text-title-xl lg:leading-12 lg:-tracking-headline-s"
                  >
                    {t('booking.$searchforroomavailability')}
                  </div>
                  <div
                    className="mt-10 lg:mt-[88px] w-full flex flex-col justify-center lg:w-[1025px] gap-10 lg:gap-14 items-center grow
                    [&_.form-items-container]:w-full [&_.form-items-container]:grow
                    [&_.main-button]:!border 
                    [&_.popover-title.placeholder]:!text-brand-soft-white/75 [&_.popover-title]:!text-sm [&_.popover-title]:!leading-6 [&_.popover-title]:!normal-case [&_.popover-title]:!font-normal [&_.popover-title]:!tracking-normal
                    [&_.dropdown.selected:not(.open)_.main-button]:border-black/75 [&_.dropdown.selected:not(.open)_.popover-title]:text-black/75 [&_.dropdown.selected:not(.open)_.arrow_svg]:!fill-black/75
                    [&_.datepicker.selected:not(.open)_.main-button]:border-black/75 [&_.datepicker.selected:not(.open)_.popover-title]:text-black/75 [&_.datepicker.selected:not(.open)_.arrow_svg]:!fill-black/75
                    [&_.open.dropdown_.popover-title.placeholder]:!text-black/90 [&_.open.datepicker_.popover-title.placeholder]:!text-black/90
                    [&_.option]:!text-sm [&_.option]:!leading-6 [&_.option]:!normal-case [&_.option]:py-3 [&_.option]:!font-normal
                    [&_.input.number-input]:text-black
                    [&_.search-container]:w-full [&_.search-container]:sm:w-auto [&_.search-container_a]:w-full
                    [&_.arrow_svg]:!fill-brand-soft-white/25 [&_.open_.arrow_svg]:!fill-black/75

                    "
                  >
                    <AvailabilityForm
                      availabilityData={availabilityData}
                      searchClass="secondary-reverse"
                      searchBtnText={t('general.$search2')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

export default BookingDropdown;
