import React from 'react';
import { ModuleBase, PreHeading, ResponsiveImage, Link } from 'components';
import External from 'assets/externalLink.svg';
import Phone from 'assets/phone.svg';
import Mail from 'assets/mail.svg';
import { isColorDark } from '@/utils';
import classNames from 'classnames';

const MapSimpleModule = ({ data }) => {
  return (
    <ModuleBase data={data}>
      <div
        className={classNames(
          'container flex flex-col items-center lg:justify-between md:items-start lg:items-center gap-8 md:gap-16 font-montserrat',
          data.alignment === 'Left' ? 'lg:flex-row' : 'lg:flex-row-reverse',
        )}
      >
        {data.mapImage && (
          <div className="w-full lg:max-w-[600px] h-[298px] md:h-[600px]">
            <ResponsiveImage
              image={data.mapImage}
              widths={{ xs: 1026, md: 2064, lg: 1800 }}
              heights={{ xs: 894, md: 1800, lg: 1800 }}
            />
          </div>
        )}
        <div className="w-full lg:max-w-1/2 lg:p-10 font-montserrat font-normal">
          <div className="location-info">
            {data.locationLabel && (
              <PreHeading
                className="color-from-bg text-base leading-5 tracking-headline-m-tablet font-semibold uppercase md:text-xl md:leading-6 md:tracking-title-m"
                aria-label="Location"
                role="heading"
                aria-level="3"
                aria-describedby="Title for the map"
              >
                {data.locationLabel}
              </PreHeading>
            )}
            {data.location && (
              <div className="color-from-bg text-smleading-6 md:text-base md:leading-6.5 my-4 md:max-w-[340px] !text-opacity-75">
                {data.location}
              </div>
            )}
            {data.primaryCta && (
              <Link
                link={data.primaryCta}
                className={classNames(
                  'font-semibold text-sm py-2 leading-6 flex items-center gap-2',
                  isColorDark(data.backgroundColour)
                    ? '[&_svg>path]:stroke-brand-soft-white'
                    : '[&_svg>path]:stroke-brand-black',
                )}
              >
                <p className="color-from-bg">{data.primaryCta.text}</p>
                <External role="presentation" />
              </Link>
            )}
          </div>
          <div className="info mt-10 md:mt-16">
            {data.infoTitle && (
              <PreHeading className="color-from-bg font-semibold text-sm leading-normal uppercase tracking-title-s">
                {data.infoTitle}
              </PreHeading>
            )}
            {data.infoText && (
              <div className="color-from-bg text-sm leading-6 md:text-base md:leading-6.5 my-6 !text-opacity-75">
                {data.infoText}
              </div>
            )}
            {(data.phoneNumber || data.emailAddress) && (
              <div className="contact-wrapper">
                {data.phoneNumber && (
                  <a
                    href={`tel:${data.phoneNumber?.text}`}
                    className={classNames(
                      'flex items-center text-sm leading-6 gap-3 mb-6',
                      isColorDark(data.backgroundColour)
                        ? '[&_svg>path]:stroke-brand-soft-white'
                        : '[&_svg>path]:stroke-brand-black',
                    )}
                  >
                    <Phone role="presentation" />
                    <p
                      className={classNames(
                        'border-b',
                        isColorDark(data.backgroundColour)
                          ? 'text-brand-soft-white border-brand-soft-white'
                          : 'text-brand-black border-brand-black',
                      )}
                    >
                      {data.phoneNumber.text}
                    </p>
                  </a>
                )}
                {data.emailAddress && (
                  <a
                    href={`mailto:${data.emailAddress?.text}`}
                    className={classNames(
                      'flex items-center text-sm leading-6 gap-3',
                      isColorDark(data.backgroundColour)
                        ? '[&_svg>path]:stroke-brand-soft-white'
                        : '[&_svg>path]:stroke-brand-black',
                    )}
                  >
                    <Mail role="presentation" />
                    <p
                      className={classNames(
                        'border-b',
                        isColorDark(data.backgroundColour)
                          ? 'text-brand-soft-white border-brand-soft-white'
                          : 'text-brand-black border-brand-black',
                      )}
                    >
                      {data.emailAddress.text}
                    </p>
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};

export default MapSimpleModule;
