import React from 'react';
import classnames from 'classnames';
import Dropdown from './Dropdown';
import Datepicker from './Datepicker';
import NumberInput from './NumberInput';
import RadioGroupInput from './RadioGroupInput';
import MultiCheckbox from './MultiCheckbox';
import FormTitleAndDescription from './FormTitleAndDescription';
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput from './PhoneInput';
import { useSelector } from 'react-redux';
import { selectIsRobot } from 'store/index';

const FormControl = ({
  id,
  type,
  label,
  name,
  error,
  options,
  selectPrompt,
  required,
  settings,
  formDefinitionName,
  ...props
}) => {
  let realType = null;
  if (['text', 'Short answer'].includes(type) && settings?.fieldType === 'number') {
    realType = 'number';
  } else if (['text', 'Short answer'].includes(type) && settings?.fieldType === 'date') {
    realType = 'datepicker';
  } else if (['text', 'Short answer'].includes(type) && settings?.fieldType === 'tel') {
    realType = 'phoneInput';
  } else if (['text', 'Short answer'].includes(type)) {
    realType = 'text';
  } else if (['checkbox', 'Data Consent'].includes(type)) {
    realType = 'checkbox';
  } else if (['select', 'Dropdown'].includes(type)) {
    realType = 'dropdown';
  } else if (['textarea', 'Long answer'].includes(type)) {
    realType = 'textarea';
  } else if (['datepicker', 'Date'].includes(type)) {
    realType = 'datepicker';
  } else if (['number'].includes(type)) {
    realType = 'number';
  } else if (['Hidden'].includes(type)) {
    realType = 'hidden';
  } else if (['Single choice'].includes(type)) {
    realType = 'radio';
  } else if (['Multiple choice'].includes(type)) {
    realType = 'multicheckbox';
  } else if (['Title and description'].includes(type)) {
    realType = 'titleAndDescription';
  } else if (['reCAPTCHA v3 with score', 'Recaptcha2'].includes(type)) {
    realType = 'recaptcha';
  }

  const isRobot = useSelector(selectIsRobot);

  let input = null;
  if (['text'].includes(realType)) {
    input = (
      <input
        id={id}
        type={realType}
        className="input"
        name={name}
        aria-label={props.placeholder}
        aria-required={required}
        {...props}
      />
    );
  } else if (realType === 'dropdown') {
    input = (
      <Dropdown
        id={name}
        className="input dropdown"
        name={name}
        options={options}
        displayEmpty={!required}
        required={required}
        {...props}
        placeholder={selectPrompt || props.placeholder}
      />
    );
  } else if (realType === 'textarea') {
    input = (
      <textarea id={name} type={realType} className="input" name={name} rows={settings?.numberOfRows} {...props} />
    );
  } else if (realType === 'datepicker') {
    input = (
      <Datepicker
        id={name}
        className="input"
        name={name}
        placeholderText={props.placeholder}
        {...props}
        maxDate={settings?.maxDate || props.maxDate}
        yearMonthSelector={settings?.yearMonthSelector}
      />
    );
  } else if (realType === 'number') {
    input = (
      <NumberInput
        id={name}
        className="input"
        type={realType}
        name={name}
        defaultValue={settings?.defaultValue !== '' ? settings?.defaultValue : 0}
        {...props}
      />
    );
  } else if (realType === 'hidden') {
    input = <input id={name} type={realType} className="input" name={name} {...props} />;
  } else if (realType === 'radio') {
    input = <RadioGroupInput options={options} required={required} settings={settings} name={name} {...props} />;
  } else if (realType === 'multicheckbox') {
    input = <MultiCheckbox options={options} required={required} settings={settings} name={name} {...props} />;
  } else if (realType === 'titleAndDescription') {
    input = (
      <FormTitleAndDescription settings={settings} name={name} formDefinitionName={formDefinitionName} {...props} />
    );
  } else if (realType === 'recaptcha') {
    input = !isRobot ? (
      <ReCAPTCHA
        className="recaptcha"
        sitekey={process.env.RECAPTCHA_SITE_KEY}
        {...props}
        onChange={(value) => props.onChange({ target: { name, value: value } })}
      />
    ) : null;
  } else if (realType === 'phoneInput') {
    input = <PhoneInput id={name} className="input" name={name} {...props} />;
  }

  const labelElement = label && realType !== 'hidden' && (
    <label
      htmlFor={realType === 'checkbox' ? id : name}
      className="label"
      dangerouslySetInnerHTML={{ __html: label }}
    />
  );

  let inputElement;

  if (realType) {
    if (realType === 'checkbox') {
      inputElement = (
        <div className="checkbox">
          <input id={id} type={realType} className="input" name={name} {...props} />
          <span className="checkmark" />
          <label htmlFor={id} className="label" dangerouslySetInnerHTML={{ __html: label || props.placeholder }} />
        </div>
      );
    } else if (realType === 'recaptcha') {
      inputElement = input;
    } else {
      inputElement = (
        <>
          {labelElement} {input}
        </>
      );
    }
  }
  return (
    <div className={classnames('input-group', `field-${name}`, { error })}>
      {inputElement || (
        <div>
          Input type <b>{type}</b> not implemented
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default FormControl;
