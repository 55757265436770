import React, { useRef, useState, useEffect } from 'react';
import { ModuleBase, Media } from 'components';
import { HeadingTag, Link, PreHeading } from 'components';
import ArrowRight from 'assets/arrowRight.svg';
import classNames from 'classnames';
import tailwindConfig from 'tailwind.config.js';

const BannerFullBleedModule = ({ data }) => {
  const [showVideo, setShowVideo] = useState(0);
  const media = useRef();

  useEffect(() => {
    const onResize = () => {
      setShowVideo(window.innerWidth >= parseInt(tailwindConfig.theme.screens.sm, 10));
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const openFullscreen = () => {
    media?.current?.openFullscreen();
  };

  return (
    <ModuleBase data={data} className="font-didot relative h-[844px] md:h-[928px] lg:h-screen lg:max-h-[800px]">
      <div className="absolute top-0 left-0 right-0 h-full w-full gradient-left-to-right" />
      <div className={classNames('absolute h-full w-full overflow-hidden', { 'lg:p-8': !data.isFullBleed })}>
        <Media
          ref={media}
          media={{
            ...data.backgroundMedia,
            videoFromGallery: showVideo ? data.backgroundMedia?.videoFromGallery : null,
            vimeoId: showVideo ? data.backgroundMedia?.vimeoId : null,
          }}
          widths={{ xs: 327, sm: 640, md: 1024, lg: 1024, xl: 1440, xxl: 2560 }}
          heights={{ xs: 844, sm: 640, md: 928, lg: 928, xl: 800, xxl: 1440 }}
          cover
        />
      </div>
      {data && (data.preHeading || data.headingTitle.heading || data.description || data.primaryCTA) && (
        <div className="w-full h-full relative" onClick={openFullscreen}>
          <div className="container mx-auto h-full flex content-end flex-wrap">
            <div className="lg:px-0 w-full lg:w-[401px] mb-10 lg:mb-20">
              <div
                className="h-min text-brand-soft-white backdrop-blur-[50px] bg-black/25 w-full px-6 py-8 md:px-8 md:py-10 lg:p-10"
                onClick={(e) => e.stopPropagation()}
              >
                {data.preHeading && (
                  <PreHeading className="font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase mb-10">
                    {data.preHeading}
                  </PreHeading>
                )}
                {data.headingTitle && (
                  <HeadingTag
                    data={data.headingTitle}
                    className="main-title font-didot text-brand-soft-white text-2xl italic font-normal leading-normal -tracking-card-s md:text-title-m md:-tracking-card-m lg:text-title-l lg:-tracking-headline-m-mobile mb-6"
                  />
                )}
                {data.description && (
                  <div
                    dangerouslySetInnerHTML={{ __html: data.description }}
                    className="font-montserrat mb-10 lg:mb-[88px] text-sm not-italic font-normal leading-6 text-brand-soft-white"
                  ></div>
                )}

                {data.primaryCTA && (
                  <Link
                    className="btn primary-light first-link w-full border-brand-soft-white/25 px-5"
                    link={data.primaryCTA}
                  >
                    <span className="font-montserrat text-brand-soft-white text-sm not-italic font-medium leading-5 tracking-button-reg uppercase">
                      {data.primaryCTA.text}
                    </span>
                    <ArrowRight className="!ml-6" role="presentation" />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </ModuleBase>
  );
};

export default BannerFullBleedModule;
