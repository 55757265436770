import React, { useState, useRef } from 'react';
import { useLayoutEffect } from 'utils';
import classNames from 'classnames';
import { Link, ResponsiveImage } from '..';

const ResortNavItem = ({ activeMenu, nav, setActiveMenu }) => {
  const [left, setLeft] = useState();
  const boxRef = useRef();

  useLayoutEffect(() => {
    onResize();
    setTimeout(() => {
      onResize();
    }, 2000);
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResize = () => {
    setLeft(boxRef.current?.offsetLeft + 16);
  };

  const className = classNames(
    'nav-item block text-xs not-italic leading-normal tracking-quote-reg-mobile uppercase font-montserrat',
    'group-hover:text-black py-6 px-4 transition whitespace-nowrap',
    activeMenu?.moduleId === nav.moduleId ? 'group-hover:opacity-100' : 'group-hover:opacity-50',
    nav.props.isActive ? 'font-bold' : 'font-normal',
  );

  return (
    <div className="cursor-pointer transition" onMouseOver={() => setActiveMenu(nav)} ref={boxRef}>
      {nav.props.navigationItemLink ? (
        <Link link={nav.props.navigationItemLink} className={className} onClick={() => setActiveMenu(null)}>
          {nav.props.navigationItemTitle}
        </Link>
      ) : (
        <div className={className} role="button" tabIndex="0">
          {nav.props.navigationItemTitle}
        </div>
      )}
      <div
        className={classNames(
          'bg-brand-soft-white absolute left-0 -mt-px w-full overflow-hidden transition-all ease-in cursor-auto h-auto resort-nav-shadow',
          activeMenu?.moduleId === nav.moduleId ? 'max-h-[600px] z-10 duration-700 delay-100' : 'max-h-0 duration-500',
        )}
      >
        {nav.props.flyOut &&
          nav.props.flyOut[0] &&
          (nav.props.flyOut[0].moduleName === 'VisualizedNavItemModel' ? (
            <div className="max-w-[1016px] mx-auto my-12 flex flex-col gap-10 ">
              <div className="flex w-full gap-10">
                {nav.props.flyOut?.map((card) => (
                  <Link
                    link={card.props.link}
                    key={card.moduleId}
                    className="w-full aspect-[2/1] font-montserrat"
                    onClick={() => setActiveMenu(null)}
                  >
                    <div>
                      <ResponsiveImage image={card.props.image} widths={{ xs: 448 }} heights={{ xs: 224 }} />
                      <div className="mt-6 text-sm not-italic font-medium leading-normal tracking-button-reg uppercase">
                        {card.props.title}
                      </div>
                      <p className="mt-1 text-xs not-italic font-normal leading-5">{card.props.description}</p>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="text-center">
                <Link
                  link={nav.props.highlightedLink}
                  className="btn link after:!bottom-2 "
                  onClick={() => setActiveMenu(null)}
                />
              </div>
            </div>
          ) : (
            <div className="my-10 flex flex-col gap-6" style={{ paddingLeft: left }}>
              <div className="flex flex-col gap-4">
                {nav.props.flyOut?.map((card) => (
                  <Link
                    key={card.moduleId}
                    link={{ ...card.props.link, text: card.props.title }}
                    className="font-montserrat text-sm not-italic font-normal leading-6 w-max"
                    onClick={() => setActiveMenu(null)}
                  />
                ))}
              </div>

              <Link
                link={nav.props.highlightedLink}
                className="btn link after:!bottom-2 w-max text-xs"
                onClick={() => setActiveMenu(null)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ResortNavItem;
