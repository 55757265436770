import { useState, useEffect, useRef } from 'react';
import { selectPageId } from '../store/index';
import { ModuleBase, EntityCard } from 'components';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import { fixColor, isColorDark, pushValuesToRouteQuery } from '@/utils';
import { useRouter } from 'next/router';
import classNames from 'classnames';

const CardListingVerticalModule = ({ data }) => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const fetchController = useRef(null);
  const pageId = useSelector(selectPageId);
  const router = useRouter();
  const { t } = useTranslation('common');
  const queryData = useRef({
    page: 1,
  });

  useEffect(() => {
    let newQuery = { page: Number(router.query.page || 1) };

    if (data?.filtersAndCards?.cards && JSON.stringify(newQuery) === JSON.stringify(queryData.current)) {
      setCards(data.filtersAndCards.cards);
      setTotalCount(data.filtersAndCards.totalCount);
    } else if (JSON.stringify(newQuery) !== JSON.stringify(queryData.current) && pageId) {
      queryData.current = newQuery;
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, data]);

  useEffect(() => {
    let newQuery = { page: Number(router.query.page || 1) };

    if (JSON.stringify(newQuery) !== JSON.stringify(queryData.current) && pageId) {
      queryData.current = { ...queryData.current, ...newQuery };
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, router.query]);

  const loadMore = () => {
    pushValuesToRouteQuery(router, { page: Number(router.query.page || 1) + 1 });
    getData();
  };

  const getData = async () => {
    setLoading(true);
    if (fetchController.current) {
      fetchController.current.abort();
    }
    const controller = new AbortController();
    fetchController.current = controller;

    const reqData = {
      pageId: pageId,
      ancestorId: data.filtersAndCards.ancestorId,
      pageSize: data.filtersAndCards.pageSize,
      pageNumber: queryData.current.page,
      cardsType: data.filtersAndCards.cardType,
    };

    const dataRes = await fetch(`/api/umbraco/api/ProductCard/GetCards`, {
      method: 'POST',
      signal: fetchController.current?.signal,
      body: JSON.stringify(reqData),
    }).catch(console.error);

    if (dataRes && dataRes.ok) {
      const data = await dataRes.json();
      setCards([...cards, ...data.cards]);
      setTotalCount(data.totalCount);
    }
    setLoading(false);
  };

  const dark = isColorDark(fixColor(data?.backgroundColour));

  return (
    <ModuleBase data={data} className="overflow-hidden py-6 md:py-10 lg:py-20">
      <div className="container flex flex-col gap-8 md:gap-12 lg:gap-10 items-center">
        {cards.map((card, index) => (
          <EntityCard
            key={index}
            data={card}
            dark={dark}
            className={classNames(
              'w-full px-0',
              card.props.alignment === 'Side' ? 'odd:lg:flex-row even:lg:flex-row-reverse' : '',
            )}
          />
        ))}

        {loading && (
          <div className="w-full flex justify-center py-10">
            <svg
              className={classNames('animate-spin h-10 w-10 ', dark ? 'text-white' : 'text-black')}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          </div>
        )}

        <button className="color-from-bg btn link w-fit px-16" onClick={loadMore} disabled={cards.length >= totalCount}>
          {t('general.$viewMore')}
        </button>
      </div>
    </ModuleBase>
  );
};
export default CardListingVerticalModule;
