import React, { useState, useContext, useRef } from 'react';
import { ModuleBase, UmbracoForm, Link, SmootherContext } from 'components';
import { fixColor, isColorDark } from '@/utils';
import classNames from 'classnames';

const FormModule = ({ data }) => {
  const [headingData, setHeadingData] = useState({
    preHeading: data.preHeading,
    heading: data.heading,
    errorHeading: data.errorHeading,
    text: data.text,
    cta: null,
  });
  const scrollSmoother = useContext(SmootherContext);
  const boxRef = useRef();

  const onFinish = () => {
    setTimeout(() => {
      scrollSmoother.scrollTo(boxRef.current, true, 'top 100px');
    }, 100);
  };

  const formDefinition = JSON.parse(JSON.stringify(data.formFields));

  formDefinition.pages?.forEach((page, i) => {
    page?.fieldsets.forEach((fieldset, j) => {
      fieldset?.columns.forEach((column, k) => {
        column?.fields.forEach((field) => {
          if (['chooseAHotel', 'resortOfInterest'].includes(field.alias)) {
            field.preValues = data.hotels.map((hotel) => ({ caption: hotel.name, value: hotel.name }));
          } else if (field.alias === 'royaltyCardLevel') {
            field.preValues = data.royaltyCardTypes.map((cardType) => ({
              caption: cardType.value,
              value: cardType.value,
            }));
          } else if (field.alias === 'enquiryType') {
            field.preValues = data.requestReasons.map((reason) => ({
              caption: reason.value,
              value: reason.value,
            }));
          } else if (field.alias === 'preferredTimeOfCall') {
            field.preValues = data.callTimes.map((callTime) => ({
              caption: callTime.value,
              value: callTime.value,
            }));
          } else if (field.alias === 'language' && data.formFields.name === 'Newsletter') {
            field.preValues = data.languages.map((lang) => ({
              caption: lang,
              value: lang,
            }));
            field.settings.defaultValue = data.languages[0];
          } else if (field.alias === 'dateOfBirth') {
            field.settings.maxDate = new Date();
            field.settings.yearMonthSelector = true;
          }
        });

        if (
          i === data.formFields.pages.length - 1 &&
          j === page?.fieldsets.length - 1 &&
          k === fieldset?.columns.length - 1
        ) {
          column?.fields.push({
            alias: 'recaptcha',
            caption: 'recaptcha',
            helpText: '',
            id: '5d4b9338-6532-4316-8607-411a7317bea8',
            pattern: '',
            patternInvalidErrorMessage: 'Please provide a valid value for recaptcha',
            placeholder: '',
            preValues: [],
            required: true,
            requiredErrorMessage: 'Please provide a value for recaptcha',
            settings: {
              theme: '',
              size: '',
              errorMessage: '',
              showLabel: 'True',
            },
            type: {
              id: 'b69deaeb-ed75-4dc9-bfb8-d036bf9d3730',
              name: 'Recaptcha2',
              renderInputType: 'Single',
              supportsPreValues: false,
              supportsUploadTypes: false,
            },
          });
        }
      });
    });
  });

  const backgroundColour = formDefinition?.cssClass === 'contact-us' ? '#C6CEBC' : '#475A4F';

  const dark = isColorDark(fixColor(backgroundColour));

  return (
    <ModuleBase
      data={{ ...data, backgroundColour }}
      className={classNames('text-black font-didot', formDefinition?.cssClass)}
    >
      <div ref={boxRef} />
      {(headingData.preHeading || headingData.errorHeading || headingData.heading || headingData.text) && (
        <div
          className={classNames(
            'section-container container flex flex-col items-center gap-4 mb-14 sm:mb-16 lg:mb-12 font-montserrat',
            dark ? 'text-brand-soft-white' : 'text-black',
          )}
        >
          {headingData.preHeading && (
            <div className="text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase">
              {headingData.preHeading}
            </div>
          )}
          {headingData.heading && (
            <div className="heading sm:text-base sm:leading-5 sm:tracking-headline-m-tablet lg:text-xl lg:leading-6 lg:tracking-quote-reg-mobile not-italic font-semibold uppercase text-center">
              {headingData.heading}
            </div>
          )}

          {headingData.errorHeading && (
            <div className="errorHeading sm:text-base sm:leading-5 sm:tracking-headline-m-tablet lg:text-xl lg:leading-6 lg:tracking-quote-reg-mobile not-italic font-semibold uppercase text-center text-error">
              {headingData.errorHeading}
            </div>
          )}

          {headingData.text && (
            <div className="text text-sm not-italic font-normal leading-6 text-center max-w-[716px]">
              {headingData.text}
            </div>
          )}
          {headingData.cta && (
            <div className="mt-20">
              <Link className={classNames('btn link', { dark })} link={headingData.cta} />
            </div>
          )}
        </div>
      )}
      <div className="container">
        {formDefinition && (
          <UmbracoForm
            className="form-container last:[&_.newsletter-name-fieldset_.fieldset-column]:min-w-32 last:[&_.newsletter-name-fieldset_.fieldset-column]:w-auto sm:px-8 lg:px-[88px]"
            formDefinition={formDefinition}
            setHeadingData={setHeadingData}
            onFinish={onFinish}
          />
        )}
      </div>
    </ModuleBase>
  );
};

export default FormModule;
