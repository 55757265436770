import React, { useState, useEffect, useRef, useContext } from 'react';
import { ModalPortal } from '@/components';
import ResortNavDesktopItem from './ResortNavDesktopItem';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import SmootherContext from '../general/SmootherContext';
import ArrowUp from 'assets/arrowUp.svg';

const ResortNav = ({ navItems, mainNav, addQueryParam, availabilityBar, filledHeader }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [detach, setDetach] = useState(false);
  const [detachAnimation, setDetachAnimation] = useState(false);
  const showTimeout = useRef();
  const hideTimeout = useRef();
  const resortNav = useRef();
  const { t } = useTranslation('common');
  const scrollSmoother = useContext(SmootherContext);

  useEffect(() => {
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScroll = () => {
    let scrollOverNav =
      window.scrollY > (mainNav?.current?.offsetHeight + resortNav?.current?.offsetHeight + 100 || window.innerHeight);

    if (scrollOverNav) {
      setDetach(true);
      if (!showTimeout.current) {
        showTimeout.current = setTimeout(() => {
          showTimeout.current = 0;
          setDetachAnimation(true);
        }, 500);
      }
      clearTimeout(hideTimeout.current);
      hideTimeout.current = 0;
    } else {
      setDetachAnimation(false);
      if (!hideTimeout.current) {
        hideTimeout.current = setTimeout(() => {
          hideTimeout.current = 0;
          setDetach(false);
        }, 300);
      }
      clearTimeout(showTimeout.current);
      showTimeout.current = 0;
    }
  };

  const scrollTop = () => {
    scrollSmoother.scrollTo(0, true);
  };

  if (!navItems || navItems.navigationItems?.length === 0) return null;

  const constent = (
    <div
      className={classNames(
        'hidden lg:block group hover:bg-brand-soft-white transition duration-300 w-full',
        filledHeader ? '' : 'backdrop-blur-[3px]',
        detach ? 'fixed resort-nav-shadow top-0' : filledHeader ? 'border-t border-t-black/10 relativ' : 'relativ',
        detach && !detachAnimation && '-translate-y-full',
        detachAnimation
          ? 'bg-brand-soft-white [&_.nav-item]:text-black'
          : filledHeader
            ? '[&_.nav-item]:text-black'
            : 'bg-brand-soft-white/5 [&_.nav-item]:text-brand-soft-white',
      )}
      onMouseOut={() => setActiveMenu(null)}
      ref={resortNav}
    >
      <div className={classNames('container px-20 flex items-center', detach ? 'justify-between' : 'justify-center')}>
        {detach && (
          <button
            className="btn h-auto p-2 !bg-black/10 !border-0 ml-10"
            onClick={scrollTop}
            aria-label={t('general.$book')}
          >
            <ArrowUp className="fill-black" />
          </button>
        )}
        <div className="flex justify-center">
          {navItems.navigationItems.map((nav) => (
            <ResortNavDesktopItem key={nav.moduleId} {...{ activeMenu, nav, setActiveMenu }} />
          ))}
        </div>
        {detach &&
          (availabilityBar ? (
            <button
              className="btn primary-dark small rounded px-8"
              onClick={() => addQueryParam({ book: true })}
              aria-label={t('general.$book')}
            >
              <span className="text-xs not-italic font-medium leading-4.5 tracking-quote-reg-mobile">
                {t('general.$book')}
              </span>
            </button>
          ) : (
            <div className="w-8" />
          ))}
      </div>
    </div>
  );

  if (detach) {
    return (
      <div className="lg:h-[63px]">
        <ModalPortal>{constent}</ModalPortal>
      </div>
    );
  } else {
    return constent;
  }
};

export default ResortNav;
