import React, { useState } from 'react';
import classNames from 'classnames';
import { default as RDatepicker, registerLocale } from 'react-datepicker';
import { enUS, tr, ru, de } from 'date-fns/locale';
import { format, getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import { useRouter } from 'next/router';
import JumpLeft from 'assets/jumpLeft.svg';
import ChevronRight from 'assets/chevronRight.svg';
import { useTranslation } from 'next-i18next';
import { PopoverMenu } from 'components';

registerLocale('en-US', enUS);
registerLocale('tr', tr);
registerLocale('ru', ru);
registerLocale('de', de);

const Datepicker = ({
  name,
  value,
  onChange,
  className,
  placeholder,
  selectsRange,
  dateFormat = 'dd/MM/yyyy',
  popoverRef,
  yearMonthSelector,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const { t } = useTranslation('common');

  const _onChange = (value) => {
    if (selectsRange) {
      if (value[0] && value[1]) {
        setOpen(false);
      }
    } else {
      setOpen(false);
    }

    const event = { target: { name, value: value } };
    if (onChange) {
      onChange(event);
    }
  };

  const prevent = (e, fn) => {
    e.preventDefault();
    fn(e);
  };

  const months = range(0, 12, 1).map((monthNum) =>
    new Date(2000, monthNum, 1).toLocaleString(router.locale, {
      month: 'long',
    }),
  );

  const customHeader = ({
    monthDate,
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
  }) => {
    new Date();

    const years = range(
      getYear(monthDate) < getYear(new Date()) ? getYear(new Date()) : getYear(monthDate),
      1940 - 1,
      -1,
    );
    return (
      <div className="flex justify-between">
        <div className="flex gap-4">
          <button
            onClick={(e) => prevent(e, decreaseYear)}
            disabled={prevYearButtonDisabled}
            aria-label={t('general.$previous')}
          >
            <JumpLeft className={classNames(prevYearButtonDisabled ? 'stroke-black/25' : 'stroke-black')} />
          </button>

          <button
            onClick={(e) => prevent(e, decreaseMonth)}
            disabled={prevMonthButtonDisabled}
            aria-label={t('general.$previous')}
          >
            <ChevronRight
              className={classNames(prevMonthButtonDisabled ? 'stroke-black/25' : 'stroke-black', 'rotate-180')}
            />
          </button>
        </div>
        {yearMonthSelector ? (
          <div className="react-datepicker__year-month-select">
            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <span className="react-datepicker__current-month">
            {monthDate.toLocaleString(router.locale, {
              month: 'long',
              year: 'numeric',
            })}
          </span>
        )}
        <div className="flex gap-4">
          <button
            onClick={(e) => prevent(e, increaseMonth)}
            disabled={nextMonthButtonDisabled}
            aria-label={t('general.$next')}
          >
            <ChevronRight className={classNames(nextMonthButtonDisabled ? 'stroke-black/25' : 'stroke-black')} />
          </button>
          <button
            onClick={(e) => prevent(e, increaseYear)}
            disabled={nextYearButtonDisabled}
            aria-label={t('general.$next')}
          >
            <JumpLeft
              className={classNames(nextYearButtonDisabled ? 'stroke-black/25' : 'stroke-black', 'rotate-180')}
            />
          </button>
        </div>
      </div>
    );
  };
  const valueStr = selectsRange
    ? props.startDate || props.endDate
      ? `${(props.startDate && format(props.startDate, dateFormat)) || ''} - ${
          (props.endDate && format(props.endDate, dateFormat)) || ''
        }`
      : null
    : value && format(value, dateFormat);

  return (
    <PopoverMenu
      open={open}
      title={valueStr ? valueStr : placeholder}
      titleClass={!valueStr && placeholder && 'placeholder'}
      onOpen={() => setOpen(true)}
      className={classNames(className, 'datepicker', value && 'selected')}
      ref={popoverRef}
    >
      <RDatepicker
        selected={value}
        onChange={_onChange}
        locale={router.locale}
        startDate={new Date()}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        name={name}
        showDisabledMonthNavigation
        renderCustomHeader={customHeader}
        inline
        selectsRange={selectsRange}
        {...props}
      />
    </PopoverMenu>
  );
};

export default Datepicker;
