import React, { useRef, useEffect, useState } from 'react';
import { ModuleBase, ResponsiveImage, HeadingTag, Link } from 'components';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import Arrow from 'assets/arrow.svg';
import ArrowRight from 'assets/arrowRight.svg';
import { useTranslation } from 'next-i18next';
import CarouselPagination from '@/components/general/CarouselPagination';
const HeroCarouselCentreAlignedModule = ({ data }) => {
  const carousel = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [panelCount, setPanelCount] = useState(0);
  const { t } = useTranslation('common');
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight);

    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  const onReady = () => {
    setPanelCount(carousel.current.panelCount);
  };

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  return (
    <ModuleBase data={data} className="relative h-screen max-h-[756px] md:h-[363px]  lg:h-[680px] font-montserrat">
      <Flicking
        ref={carousel}
        align="center"
        useResizeObserver={true}
        horizontal={true}
        circular={true}
        onWillChange={willChange}
        onReady={onReady}
      >
        {data.mediaSlides.map((slide, index) => (
          <div key={index} className="h-screen max-h-[756px] md:h-[363px] lg:h-[680px] w-full">
            <div className="absolute w-full h-full flex justify-center items-end">
              <div className="w-full inner  pb-6 lg:pb-10 ">
                <div className="absolute pointer-events-none top-0 left-0 right-0 h-full w-full z-10 gradient-bottom-to-top" />

                <div className="text-wrapper container relative z-20 h-full md:flex md:gap-6 md:justify-between lg:gap-10 lg:justify-start lg:px-[148px]">
                  <div className="title-and-description z-20 mb-6 md:mb-0 md:w-[520px] lg:w-[784px]">
                    {slide.headingTitle && (
                      <HeadingTag
                        data={{
                          heading: slide.headingTitle,
                          htag: '',
                        }}
                        className="main-title mb-2 text-brand-soft-white font-montserrat text-sm not-italic font-semibold leading-normal tracking-title-s uppercase"
                      />
                    )}

                    {slide.description && (
                      <div
                        dangerouslySetInnerHTML={{ __html: slide.description }}
                        className="text-brand-soft-white text-sm not-italic font-normal leading-6"
                      ></div>
                    )}
                  </div>

                  <div className="link-wrapper z-20 flex flex-col items-end justify-end lg:flex-row">
                    {slide.primaryCTA && (
                      <Link
                        className={`btn primary-light first-link md:self-start md:mr-3 border-brand-soft-white/25 w-full 
                                  text-xs not-italic font-medium leading-4.5 tracking-quote-reg-mobile uppercase
                                  md:w-fit
                                  lg:text-sm lg:leading-5 lg:tracking-button-reg lg:mt-auto
                                  ${slide.secondaryCTA ? 'mb-3 lg:mb-0' : 'mb-0'}`}
                        link={slide.primaryCTA}
                      >
                        <span>{slide.primaryCTA.text}</span>
                        <ArrowRight className="!ml-7" role="presentation" />
                      </Link>
                    )}

                    {slide.secondaryCTA && (
                      <Link
                        className="btn secondary-reverse  secondary-link md:self-start w-full
                                    text-xs not-italic font-medium leading-4.5 tracking-quote-reg-mobile uppercase
                                    md:w-fit
                                    lg:text-sm lg:leading-5 lg:tracking-button-reg lg:mt-auto"
                        link={slide.secondaryCTA}
                      >
                        <span>{slide.secondaryCTA.text}</span>
                        <ArrowRight className="!ml-7" role="presentation" />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <ResponsiveImage
              image={slide.image}
              widths={{ xs: 430, sm: 640, md: 1024, lg: 1024, xl: 1280, xxl: 1536 }}
              heights={{ xs: 756, sm: 756, md: 363, lg: 680, xl: 680, xxl: 680 }}
            />
          </div>
        ))}
      </Flicking>

      <CarouselPagination
        dark={true}
        current={currentIndex}
        total={panelCount}
        className="absolute z-20 left-1/2 top-10 -translate-x-1/2 md:top-6 lg:top-10"
        moduleName={data.moduleName}
      />

      <div
        className={`absolute px-6 md:px-10 lg:px-[60px] w-full  lg:-translate-y-1/2 z-20 flex justify-between
        ${screenHeight < 700 ? 'top-[40%]' : 'top-[47%] md:top-[40%] lg:top-1/2'}
        `}
      >
        <button
          className="btn text-light hover:!bg-brand-green lg:hover:!bg-grey6 border-none circle bg-brand-green p-0 w-9 h-9 lg:w-12 lg:h-12 z-20"
          onClick={move}
          aria-label={t('general.$previous')}
        >
          <Arrow role="presentation" className="rotate-180 scale-75" />
        </button>

        <button
          className="btn text-light hover:!bg-brand-green lg:hover:!bg-grey6  border-none circle bg-brand-green p-0 w-9 h-9 lg:w-12 lg:h-12 z-20"
          onClick={() => move(1)}
          aria-label={t('general.$next')}
        >
          <Arrow role="presentation" className="scale-75" />
        </button>
      </div>
    </ModuleBase>
  );
};
export default HeroCarouselCentreAlignedModule;
