import PropTypes from 'prop-types';
import { ResponsiveImage, Link, PreHeading } from '..';
import ArrowRight from 'assets/arrowRight.svg';

const ResortCard = ({ data }) => {
  return (
    <div className="relative resort-card text-brand-soft-white w-full h-[468px] md:h-[516px] lg:h-[612px]">
      {data.image && (
        <ResponsiveImage
          image={data.image}
          widths={{ xs: 258, md: 352, lg: 424 }}
          heights={{ xs: 468, md: 516, lg: 612 }}
          className="h-full w-full object-cover"
          image-class="h-full w-full object-cover"
        />
      )}

      <div className="content-wrapper gradient-bottom-to-top absolute left-0 top-0 w-full h-full font-didot flex flex-col justify-between grow p-6">
        <div className="category-wrapper w-fit bg-black/25 px-5 py-2 rounded-full font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase">
          {data.preHeading && (
            <PreHeading className="font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase">
              {data.preHeading}
            </PreHeading>
          )}
        </div>
        <div className="text-wrapper">
          {data.headingTitle && (
            <div
              role="heading"
              aria-level="7"
              className="font-didot text-2xl font-normal leading-normal -tracking-card-s mb-4
                            lg:text-title-l lg:-tracking-headline-m-mobile"
              dangerouslySetInnerHTML={{ __html: data.headingTitle }}
            ></div>
          )}

          {data.description && (
            <p className="font-montserrat text-sm not-italic font-normal leading-6 mb-6 lg:mb-8">{data.description}</p>
          )}

          {data.primaryCta && (
            <Link
              className="btn primary-light first-link md:self-start md:mr-3 border-brand-soft-white/25 w-full md:w-fit"
              link={data.primaryCta}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: data.headingTitle?.replace(/<(em)>/g, '').replace(/<\/(em)>/g, ''),
                }}
              ></div>
              <ArrowRight className="!ml-6" role="presentation" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

ResortCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ResortCard;
