import { HeadingTag, ModuleBase, Link } from 'components';
// import Instagram from 'assets/instagram.svg';
import Facebook from 'assets/facebook.svg';
import Linkedin from 'assets/linkedin.svg';
// import Youtube from 'assets/youtube.svg';
import Mail from 'assets/mail.svg';
import Twitter from 'assets/twitter.svg';
const HeroArticleModuleModel = ({ data }) => {
  const { label, title, description, readTime, sharingLinksModel } = data || {};
  return (
    <ModuleBase data={data} className="text-center">
      <div className="hero-article container pt-[134px] pb-8 md:pt-[160px] md:pb-10 lg:pt-[204px] lg:pb-14">
        <div className="data-wrapper md:max-w-[608px] lg:max-w-[1016px] md:mx-auto">
          {(label || readTime) && (
            <div className="pre-heading-wrapper mb-8 lg:mb-10">
              {label && (
                <span className="font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase">
                  {label}
                </span>
              )}{' '}
              <span className="mx-2 font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase">
                |
              </span>
              {readTime && (
                <span className="font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase">
                  {readTime}
                </span>
              )}
            </div>
          )}

          {title && (
            <HeadingTag
              data={title}
              className="main-title font-didot text-title-l italic font-normal leading-10 -tracking-headline-m-mobile mb-10
                        md:text-4xl md:leading-[44px] md:-tracking-headline-l-mobile
                        lg:mb-12 lg:text-headline-m lg:leading-12 lg:-tracking-headline-l-tablet"
            />
          )}

          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className="font-montserrat text-sm not-italic font-normal leading-6"
          ></div>

          <div className="social-link-wrapper flex justify-center gap-6 mt-14 md:mt-[72px] lg:mt-20">
            {sharingLinksModel.twitterSharingLink && (
              <Link link={{ text: '', href: sharingLinksModel.twitterSharingLink }}>
                <Twitter />
              </Link>
            )}

            {sharingLinksModel.facebookSharingLink && (
              <Link link={{ text: '', href: sharingLinksModel.facebookSharingLink }}>
                <Facebook />
              </Link>
            )}

            {sharingLinksModel.linkedInSharingLink && (
              <Link link={{ text: '', href: sharingLinksModel.linkedInSharingLink }}>
                <Linkedin />
              </Link>
            )}

            {sharingLinksModel.emailSharingLink && (
              <Link link={{ text: '', href: sharingLinksModel.emailSharingLink }}>
                <Mail />
              </Link>
            )}
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};
export default HeroArticleModuleModel;
