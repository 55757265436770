import React, { useState, useEffect, useRef, useContext } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { getSearchFilters, pushValuesToRouteQuery, fixColor, isColorDark } from 'utils';
import classNames from 'classnames';
import { selectPageId } from '../store/index';
import { ModuleBase, EntityCard, Pagination, Tabs, HeadingTag, SmootherContext } from '@/components';
import { useTranslation } from 'next-i18next';

const CardListingGridModule = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [cards, setCards] = useState([]);
  const [showCards, setShowCards] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const router = useRouter();
  const fetchController = useRef(null);
  const tabsElement = useRef();
  const { backgroundColour } = data || {};
  const queryData = useRef({
    filters: [],
    page: 1,
  });
  const pageId = useSelector(selectPageId);
  const { t } = useTranslation('common');
  const scrollSmoother = useContext(SmootherContext);

  let filters = null;
  if (data?.filtersAndCards?.filter) {
    filters = data?.filtersAndCards?.filter[0].filters?.map((filter) => ({
      tabTitle: filter.name,
      ...filter,
    }));
  }

  const tabs = filters?.length > 0 ? [{ tabTitle: t('general.$all') }, ...filters] : null;

  useEffect(() => {
    let newFilters = {
      filters: getSearchFilters(router),
      page: Number(router.query.page || 1),
    };

    if (
      data?.filtersAndCards?.cards &&
      data?.filtersAndCards?.cards.length > 0 &&
      JSON.stringify(newFilters) === JSON.stringify(queryData.current)
    ) {
      setCards(data.filtersAndCards.cards);
      setTotalCount(data.filtersAndCards.totalCount);
    } else if (pageId) {
      queryData.current = newFilters;
      getData();
    }
    const categoryIds = queryData.current.filters?.map((filter) => filter.FieldGuids)[0];

    setActiveTab(categoryIds && categoryIds[0] ? tabs?.findIndex((tab) => tab.filterGuid === categoryIds[0]) : 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, data]);

  useEffect(() => {
    let newFilters = {
      filters: getSearchFilters(router),
      page: Number(router.query.page || 1),
    };

    if (JSON.stringify(newFilters) !== JSON.stringify(queryData.current) && pageId) {
      queryData.current = { ...queryData.current, ...newFilters };
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, router.query]);

  const getData = async () => {
    setShowCards(false);
    const animationPromise = new Promise((res) => setTimeout(() => res(), 500));
    if (fetchController.current) {
      fetchController.current.abort();
    }
    const controller = new AbortController();
    fetchController.current = controller;

    const reqData = {
      pageId: pageId,
      ancestorId: data.filtersAndCards.ancestorId,
      //Filters: filters.current.filters,
      ids: queryData.current.filters?.map((filter) => filter.FieldGuids)[0] || [],
      pageSize: data.filtersAndCards.pageSize,
      pageNumber: queryData.current.page,
      cardsType: data.filtersAndCards.cardType,
      showFilters: true,
      filters: [
        {
          fieldName: data?.filtersAndCards?.filter && data.filtersAndCards.filter[0].filterLabel,
          FieldGuids: queryData.current.filters?.map((filter) => filter.FieldGuids)[0] || [],
        },
      ],
    };

    const response = fetch(`/api/umbraco/api/ProductCard/GetCards`, {
      method: 'POST',
      signal: fetchController.current?.signal,
      body: JSON.stringify(reqData),
    }).catch(console.error);

    Promise.all([response, animationPromise]).then(async (values) => {
      const dataRes = values[0];
      if (dataRes && dataRes.ok) {
        const data = await dataRes.json();
        setCards(data.cards);
        setTotalCount(data.totalCount);
      }
      setTimeout(() => {
        setShowCards(true);
      }, 100);
    });
  };

  const onTabChange = (index, tab) => {
    pushValuesToRouteQuery(router, { category: tab.filterGuid, page: null });
  };

  const dark = isColorDark(fixColor(data?.backgroundColour));

  return (
    <ModuleBase data={data} className="py-20">
      {data.headingTitle && (
        <HeadingTag
          data={data.headingTitle}
          className="main-title font-didot mb-10 md:mb-[72px] lg:mb-14 text-center
                      text-title-l not-italic font-normal leading-8 -tracking-headline-m-mobile
                      md:text-5xl md:leading-10 md:-tracking-headline-m-tablet
                      lg:text-headline-m lg:leading-12 lg:-tracking-headline-l-tablet"
        />
      )}
      <div ref={tabsElement} />
      {tabs && (
        <Tabs
          ref={tabsElement}
          tabs={tabs}
          activeTab={activeTab}
          onChange={onTabChange}
          dark={dark}
          className="mb-10 lg:mb-[72px] [&_.tab-item-wrapper]:md:justify-between [&_.tab-item]:sm:w-full"
        />
      )}

      <div className="container min-h-[600px] flex flex-col justify-between">
        <div className="card-wrapper flex flex-col gap-x-6 gap-y-6 md:flex-row md:flex-wrap lg:gap-y-10">
          {cards.map((card) => (
            <div
              className={classNames(
                'w-full md:w-[48%] lg:w-[31%] xl:w-[32%] transition-all duration-500',
                card.moduleName !== 'ArticleCard' && 'flex',
                showCards ? 'opacity-100' : 'opacity-0',
              )}
              key={card.moduleId}
            >
              <EntityCard data={card} />
            </div>
          ))}
        </div>
        <div>
          <Pagination
            className={classNames('transition-all duration-500', showCards ? 'opacity-100' : 'opacity-0')}
            totalCount={totalCount}
            pageSize={data.filtersAndCards.pageSize}
            queryMode
            onChange={() => scrollSmoother.scrollTo(tabsElement.current, true, 'top 100px')}
            backgroundColour={backgroundColour}
          />
        </div>
      </div>
    </ModuleBase>
  );
};
export default CardListingGridModule;
