import PropTypes from 'prop-types';
import { ResponsiveImage, PreHeading } from '..';
import classNames from 'classnames';

// eslint-disable-next-line no-unused-vars
const OverviewCard = ({ data, dark, className, numberOfColumns, loading, ...props }) => {
  return (
    <div
      className={classNames(
        'main-wrapper flex flex-col',
        dark ? 'bg-background-spotlight' : 'bg-background-card',
        className,
      )}
      {...props}
    >
      <div className="img-wrapper aspect-[332/250] w-full lg:aspect-[392/408]">
        {data.image && (
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 498, md: 498, lg: 392 }}
            heights={{ xs: 375, md: 375, lg: 408 }}
            className="h-full w-full object-cover"
            image-class="h-full w-full object-cover"
          />
        )}
      </div>
      <div className="content-wrapper font-didot mx-4 mt-6 mb-4 md:my-6 lg:mx-5 flex flex-col justify-between grow">
        <div className="text-wrapper">
          {data.headingTitle && (
            <p
              className={`mb-4 font-didot text-2xl italic font-normal leading-normal -tracking-card-s
                          lg:text-title-m lg:-tracking-card-m ${dark ? 'text-brand-soft-white' : 'text-brand-black'}
            `}
              role="heading"
              aria-level="7"
            >
              {data.headingTitle}
            </p>
          )}

          {data.subHeading && (
            <PreHeading
              className={`${
                dark ? 'text-brand-soft-white/75' : 'text-brand-black/75'
              } font-montserrat mb-2 text-sm not-italic font-semibold leading-6 mr-2 lg:mr-4`}
            >
              {data.subHeading}
            </PreHeading>
          )}

          {data.description && (
            <div
              className={`font-montserrat text-sm not-italic font-normal leading-6 mr-2 lg:mr-4 ${
                dark ? 'text-brand-soft-white/75' : 'text-brand-black/75'
              }`}
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

OverviewCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OverviewCard;
