import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { gsap } from 'gsap/dist/gsap';
import tailwindConfig from '../../tailwind.config.js';
import { SplitText } from 'gsap/dist/SplitText';
import { useLayoutEffect } from 'utils';
import { useSelector } from 'react-redux';
import { selectIsRobot } from 'store/index';

const TextRevealAnimation = ({ className, selector = 'p', ...props }) => {
  const boxRef = useRef();
  const tl = useRef();
  const splitText = useRef();
  const isRobot = useSelector(selectIsRobot);

  useEffect(() => {
    if (!isRobot) {
      gsap.matchMedia().add(`(min-width: ${tailwindConfig.theme.screens.lg})`, () => {
        boxRef.current?.classList.remove('lg:opacity-0');

        if (tl.current) {
          tl.current.kill();
        }
        tl.current = gsap.timeline();

        if (!splitText.current && boxRef.current) {
          splitText.current = new SplitText([...boxRef.current.querySelectorAll(selector)]);

          const chars = splitText.current.lines;

          gsap.fromTo(
            chars,
            {
              y: 50,
              opacity: 0,
            },
            {
              scrollTrigger: {
                trigger: boxRef.current,
                start: 'top bottom',
                toggleActions: 'play pause resume reverse',
              },

              y: 0,
              opacity: 1,
              stagger: 0.05,
              duration: 1.5,
              ease: 'power3.out',
            },
          );
        }
      });
    }
    return () => {
      tl.current?.kill();
    };
  }, [selector, isRobot]);

  useLayoutEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResize = () => {
    if (window.innerWidth < parseInt(tailwindConfig.theme.screens.lg, 10)) {
      splitText.current?.revert();
      splitText.current = null;
    }
  };

  return <div ref={boxRef} className={classNames(!isRobot && 'lg:opacity-0', className)} {...props} />;
};

export default TextRevealAnimation;
