import React from 'react';
import PropTypes from 'prop-types';
import { isColorDark } from 'utils';

const QuoteSectionContent = ({
  quoteWrapperClassName = '',
  authorSourceWrapperClassName = '',
  data,
  showBottomSpacing = false,
  ...props
}) => {
  const { quote, author, source, backgroundColour } = data || {};
  return (
    <div {...props}>
      <div className={`quote-wrapper color-from-bg ${quoteWrapperClassName}`}>
        {quote && (
          <div
            role="heading"
            aria-level="7"
            dangerouslySetInnerHTML={{ __html: quote }}
            className={`font-montserrat mb-[72px] text-xl not-italic font-normal leading-8 -tracking-quote-reg-mobile
                        md:text-2xl md:leading-10 md:-tracking-quote-reg-tablet
                        lg:text-title-l lg:leading-12 lg:-tracking-quote-reg
                         [&_em]:font-didot [&_em]:text-2xl [&_em]:leading-8 [&_em]:-tracking-headline-m-tablet
                        [&_em]:md:text-title-l [&_em]:md:leading-10 [&_em]:md:-tracking-headline-l
                       [&_em]:lg:text-title-xl [&_em]:lg:leading-12 [&_em]:lg:tracking-[-0.8px]
                       ${isColorDark(backgroundColour) ? '[&_em]:text-light-green' : '[&_em]:text-background-spotlight'}
            `}
          ></div>
        )}
        <div className={`author-source-wrapper ${authorSourceWrapperClassName}`}>
          {author && (
            <div
              dangerouslySetInnerHTML={{ __html: author }}
              className="mb-0.5 font-montserrat text-base not-italic font-semibold leading-6 tracking-quote-author"
            ></div>
          )}
          {source && (
            <div
              dangerouslySetInnerHTML={{ __html: source }}
              className="font-montserrat text-base not-italic font-normal leading-6 tracking-quote-autho"
            ></div>
          )}
        </div>
      </div>
      {showBottomSpacing && <div className="mt-20 spacer"></div>}
    </div>
  );
};

QuoteSectionContent.propTypes = {
  data: PropTypes.any.isRequired,
  buttonWrapperClassName: PropTypes.string,
};

export default QuoteSectionContent;
