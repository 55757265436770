import React from 'react';
import { Link } from 'components';
import Chevron from 'assets/chevron.svg';
import { ModuleBase } from 'components';

const BreadcrumbsModel = ({ data }) => {
  return (
    <ModuleBase data={data} className="font-montserrat bg-background-primary">
      <div className="container pt-6 pb-4 flex items-center gap-4 text-xs tracking-title-m leading-normal uppercase overflow-x-auto">
        {data.crumbs?.map((link, index) =>
          link.href ? (
            <React.Fragment key={index}>
              <Link className="font-normal shrink-0" link={link} />
              <span>
                <Chevron role="presentation" className="stroke-black" />
              </span>
            </React.Fragment>
          ) : (
            <span key={index} className="font-semibold shrink-0">
              {link.text}
            </span>
          ),
        )}
      </div>
    </ModuleBase>
  );
};
export default BreadcrumbsModel;
