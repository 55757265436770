import PropTypes from 'prop-types';
import GenericCardSmall from './GenericCardSmall';
import ResortCard from './ResortCard';
import ProductCard from './ProductCard';
import ArticleCardSmall from './ArticleCardSmall';
import ExperienceCard from './ExperienceCard';
import OverviewCard from './OverviewCard';
import SmallProductCard from './SmallProductCard';
import classNames from 'classnames';

const EntityCard = ({ data, large, className, ...props }) => {
  let _props = { ...props };
  let Component = null;
  if (data.moduleName === 'GenericCard') {
    Component = GenericCardSmall;
  } else if (data.moduleName === 'ResortCard') {
    Component = ResortCard;
  } else if (data.moduleName === 'ProductCard') {
    Component = ProductCard;
  } else if (data.moduleName === 'ArticleCard') {
    Component = ArticleCardSmall;
  } else if (data.moduleName === 'ExperienceCard') {
    Component = ExperienceCard;
  } else if (data.moduleName === 'OverviewCard') {
    Component = OverviewCard;
  } else if (data.moduleName === 'SmallProductCard') {
    Component = SmallProductCard;
  }

  return Component ? (
    <Component
      data={data.props}
      className={classNames(className, `${data.moduleName}${large ? 'Large' : 'Small'}`)}
      {..._props}
    />
  ) : (
    data.moduleName + ' card not implemented'
  );
};

EntityCard.propTypes = {
  data: PropTypes.object.isRequired,
  large: PropTypes.bool,
};

export default EntityCard;
