import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { ResponsiveImage, Link, PreHeading } from 'components';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import CarouselPagination from '@/components/general/CarouselPagination';
import { useRouter } from 'next/router';

const ProductCard = ({ data, dark, className, ...props }) => {
  const { t } = useTranslation('common');
  const { alignment } = data || '';
  const [currentIndex, setCurrentIndex] = useState(0);
  const [panelCount, setPanelCount] = useState(0);
  const carouselInner = useRef();
  const router = useRouter();

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  const onReady = () => {
    setPanelCount(carouselInner.current.panelCount);
  };

  const openBook = () => {
    router.replace(
      {
        query: { ...router.query, book: true },
      },
      undefined,
      { shallow: true },
    );
  };

  return (
    <div
      className={classNames(
        'product-card flex flex-col p-4 md:p-6 font-primary max-w-[1224px]',
        className,
        alignment === 'Side' ? 'lg:py-10 lg:px-8 lg:gap-26' : 'lg:py-8 lg:px-6',
      )}
      {...props}
    >
      {data.preHeading && alignment !== 'Side' && (
        <PreHeading
          className={classNames(
            'color-from-bg font-montserrat !text-opacity-50 mb-2 text-xs text-black/50 not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase hidden',
            alignment === 'Side' ? '' : 'lg:block',
          )}
        >
          {data.preHeading}
        </PreHeading>
      )}

      {data.headingTitle && alignment !== 'Side' && (
        <p
          role="heading"
          aria-level="7"
          className={classNames(
            'color-from-bg font-montserrat text-base not-italic font-semibold leading-5 tracking-headline-m-tablet uppercase mb-3 hidden',
            'md:text-xl md:leading-6 md:mb-4 lg:mb-5 md:tracking-title-m',
            alignment === 'Side' ? '' : 'lg:block',
          )}
        >
          {data.headingTitle}
        </p>
      )}
      <div className={classNames('img-wrapper w-full', alignment === 'Side' ? 'lg:w-[656px] lg:min-h-full' : '')}>
        <Flicking
          ref={carouselInner}
          useResizeObserver={true}
          horizontal={true}
          circular={true}
          circularFallback="bound"
          onWillChange={willChange}
          onReady={onReady}
        >
          {data.images.map((image, imageIndex) => (
            <div
              className={classNames(
                'img-items mx-auto relative w-full aspect-[295/189] h-[189px] md:aspect-[640/334] md:h-full mb-5 md:mb-8 cursor-grab',
                alignment === 'Side' ? 'lg:h-[424px] lg:aspect-[656/424]' : 'lg:h-[447px] lg:aspect-[1176/447]',
              )}
              key={imageIndex}
            >
              <ResponsiveImage
                image={image}
                widths={{ xs: 885, md: 1280, lg: 1440, xl: 1900 }}
                heights={{ xs: 567, md: 668, lg: 894, xl: 894 }}
                className="h-full w-full object-cover"
                imgProps={{
                  className: 'h-full w-full object-cover pointer-events-none',
                }}
              />
            </div>
          ))}
        </Flicking>

        <CarouselPagination
          dark={dark}
          current={currentIndex}
          total={panelCount}
          className={`ml-auto  ${alignment === 'Side' ? 'lg:mx-auto' : 'lg:ml-0'}`}
          moduleName="CardListingVerticalModule"
        />
      </div>

      <div
        className={classNames(
          'content-wrapper font-primary mt-8 flex flex-col justify-between grow',
          alignment === 'Side' ? 'lg:mt-0' : 'lg:flex-row-reverse lg:mt-12',
        )}
      >
        <div className={classNames('text-wrapper', alignment === 'Side' ? 'lg:max-w-[400px]' : 'lg:w-[514px]')}>
          {data.preHeading && (
            <PreHeading
              className={classNames(
                'color-from-bg font-montserrat mb-2 text-xs text-black/50 not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase lg:hidden',
              )}
            >
              {data.preHeading}
            </PreHeading>
          )}

          {data.headingTitle && (
            <p
              className={classNames(
                `color-from-bg font-montserrat text-base not-italic font-semibold leading-5 tracking-headline-m-tablet uppercase mb-3
                          md:text-xl md:leading-6 md:mb-4 md:tracking-title-m`,
                alignment === 'Side' ? '' : 'lg:hidden',
              )}
              role="heading"
              aria-level="7"
            >
              {data.headingTitle}
            </p>
          )}

          {data.description && (
            <p className="color-from-bg font-montserrat font-normal text-xs not-italic leading-5 text-black/75 md:text-sm md:leading-6">
              {data.description}
            </p>
          )}

          <div className={classNames('details-desktop hidden lg:block', alignment === 'Side' ? 'lg:my-6' : ' lg:mt-8')}>
            {data.keyFeatures.map((feature, index) => (
              <div key={index} className="py-3 md:w-[48%] flex gap-8 items-center">
                <div className="w-6 h-6">
                  <ResponsiveImage
                    image={feature.logo}
                    imgProps={{
                      className: dark ? 'invert w-full h-full block object-cover' : 'w-full h-full block object-cover',
                    }}
                  />
                </div>
                <span className="color-from-bg font-montserrat font-normal text-sm not-italic leading-6 capitalize">
                  {feature.text}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="details-wrapper my-6 md:my-10 lg:hidden">
          <div className="first-column md:grid md:gap-x-24 md:grid-cols-[auto_auto] md:w-fit">
            {data.keyFeatures.map((feature, index) => (
              <div key={index} className="py-3 md:py-4 flex items-center gap-8 max-w-fit">
                <div className="w-6 h-6">
                  <ResponsiveImage
                    image={feature.logo}
                    imgProps={{
                      className: dark ? 'invert w-full h-full block object-cover' : 'w-full h-full block object-cover',
                    }}
                  />
                </div>
                <span className="font-montserrat font-normal text-sm not-italic leading-6 capitalize color-from-bg">
                  {feature.text}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="link-wrapper">
          {(data.primaryCta || data.secondaryCta) && (
            <div
              className={classNames(
                'button-wrapper lg:mt-0 flex flex-col gap-3 md:flex-row',
                alignment === 'Side' ? 'lg:mb-[34px]' : '',
              )}
            >
              <button
                className={classNames('btn w-full md:w-fit', dark ? 'primary color-from-bg' : 'primary-dark')}
                onClick={openBook}
              >
                {t('general.$bookNow')}
              </button>
              <Link
                className={classNames(
                  'btn secondary w-full md:w-fit',
                  dark ? 'color-from-bg' : '!text-brand-black !border-brand-black/25',
                )}
                link={data.secondaryCta}
              >
                {t('general.$explore')}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  data: PropTypes.object.isRequired,
};
export default ProductCard;
