import classNames from 'classnames';
import ResortNavItem from './ResortNavItem';
import { Link, LanguageSelect } from 'components';
import { useTranslation } from 'next-i18next';
import NavArrow from 'assets/navArrow.svg';

const ResortNavColumn = ({ navItems, level, activeMenu, setActiveMenu, close, cta, ctaClass, metadata }) => {
  const lKey = `l${level}`;
  const _setActiveMenu = (index, subMenu) => {
    let newValue = {};
    newValue[`m${index}`] = subMenu;
    setActiveMenu(newValue);
  };
  const { t } = useTranslation('common');

  const moduleName = navItems[0]?.moduleName;

  const navMap = navItems?.map((nav, index) => (
    <ResortNavItem
      navItem={nav}
      key={index}
      level={level}
      activeMenu={activeMenu ? (activeMenu[lKey] ? activeMenu[lKey][`m${index}`] : {}) : {}}
      setActiveMenu={(subMenu) => _setActiveMenu(index, subMenu)}
      close={close}
      dividerTop={level === 0 && navItems.length - 1 == index}
      moduleName={moduleName}
    />
  ));

  return (
    <div
      className={classNames(
        'absolute top-0 w-full h-full transition-transform duration-700 ease-out origin-right overflow-hidden',
        !(activeMenu && activeMenu[lKey]) && 'translate-x-full lg:-translate-x-full',
        level ? 'left-0 lg:left-1/3' : 'left-0',
        { 'z-0': level === 0, 'z-10 lg:-z-10': level === 1, 'z-20 lg:-z-20': level === 2 },
      )}
    >
      <div className="w-full h-full relative pointer-events-none">
        <div className="lg:w-1/3 h-full pt-[90px] lg:pb-0 lg:pt-[116px] pointer-events-auto bg-background-primary pb-[75px]">
          {level !== 0 && (
            <button
              className="pl-8 sm:pl-10 lg:pl-16 pt-10 cursor-pointer text-black flex items-center py-3 text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase font-montserrat"
              onClick={() => setActiveMenu(null)}
            >
              <NavArrow className="-rotate-180 fill-black mr-2" role="presentation" />
              <span className="-mt-px">{t('general.$back')}</span>
            </button>
          )}
          <div
            className={classNames(
              'overflow-y-scroll flex flex-col pl-8 sm:pl-10 pr-8 sm:pr-10',
              level === 0 ? 'pt-7 h-full' : 'h-[calc(100%-76px)]',
            )}
          >
            {moduleName === 'VisualizedNavItemModel' ? (
              <div className="w-full flex flex-col sm:flex-row gap-6 py-3">{navMap}</div>
            ) : (
              navMap
            )}

            {level === 0 && (
              <>
                <hr className="text-black/10 my-5 lg:my-2" />
                <Link
                  link={{ href: '/' }}
                  className="flex my-3 lg:py-2 font-montserrat text-base not-italic font-normal leading-6.5 cursor-pointer items-center justify-between duration-500 [&_svg]:duration-500 text-black hover:text-black w-max"
                >
                  {t('general.$backtoMaxxRoyalHome')}
                </Link>
                <div className="w-max lg:hidden mb-10 relative">
                  <LanguageSelect
                    metadata={metadata}
                    className="[&_.main-button]:px-0 [&_.main-button_svg]:!fill-black [&_.main-button_svg]:!stroke-transparent"
                    onChange={close}
                    placement="top"
                  />
                </div>
              </>
            )}

            {cta && (
              <>
                <hr className="text-black/10 my-5 lg:my-2" />
                <Link className={classNames(ctaClass, 'mb-10')} link={cta} onClick={close} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResortNavColumn;
