import { ModuleBase, Media, ParallaxAnimation } from 'components';
import SectionContent from 'components/cards/SectionContent.jsx';
import { useEffect, useState } from 'react';
import tailwindConfig from '../tailwind.config.js';

const RichTextAndOneMediaModule = ({ data }) => {
  const screens = tailwindConfig.theme.screens;
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const setWindowDimension = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('load', setWindowDimension);
    window.addEventListener('resize', setWindowDimension);

    return () => {
      window.removeEventListener('load', setWindowDimension);
      window.removeEventListener('resize', setWindowDimension);
    };
  }, []);

  return (
    <ModuleBase data={data}>
      <div
        className={`container main-data flex lg:justify-between flex-col-reverse ${
          data.mediaAlignment === 'Left' ? 'lg:flex-row-reverse' : 'lg:flex-row'
        }`}
      >
        <div
          className={`text-wrapper lg:max-w-[42%] xxl:max-w-[45%] color-from-bg ${
            data.mediaOrientation === 'Portrait' ? 'lg:mt-[112px]' : 'lg:my-auto'
          }`}
        >
          <SectionContent
            data={data}
            preHeadingClassName="font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase mb-6 lg:text-sm lg:font-medium lg:tracking-button-reg"
            headingClassName="font-montserrat text-base not-italic font-semibold leading-5 tracking-headline-m-tablet uppercase md:text-xl md:leading-6 md:tracking-quote-reg-mobile"
            descriptionClassName="text-sm not-italic font-normal leading-6 lg:leading-6.5 lg:text-base font-montserrat text-black/75"
          />
        </div>

        {data.mediaOrientation === 'Portrait' && (
          <div
            className={`img-wrapper w-full mx-auto mb-10 my-auto md:w-full lg:mx-0 relative 
            ${data.mediaAlignment === 'Left' ? 'lg:mb-auto md:self-start' : 'lg:mt-auto md:self-end'}
            ${
              windowHeight < 800 && windowWidth > parseInt(screens.sm)
                ? 'lg:h-[75vh] lg:w-[38%]'
                : 'h-[401px] md:h-[736px] max-w-[342px] md:max-w-[688px] lg:max-w-[600px] sm:aspect-[342/401] lg:aspect-[600/736] lg:h-fit lg:w-[48%]'
            }
            `}
          >
            <ParallaxAnimation move={300} zoom>
              <Media
                cover
                media={data.media}
                widths={{ xs: 401, sm: 401, md: 768, lg: 600, xl: 768, xxl: 768 }}
                heights={{ xs: 470, sm: 470, md: 787, lg: 906, xl: 1160, xxl: 1160 }}
                className="h-full w-full object-cover"
              />
            </ParallaxAnimation>
          </div>
        )}
        {data.mediaOrientation === 'Landscape' && (
          <div
            className={`img-wrapper w-full mb-10 md:mb-14 aspect-[342/237] h-[237px] mx-auto my-auto md:aspect-[688/477] md:h-[477px] lg:aspect-[600/416] lg:h-[416px] relative ${
              data.mediaAlignment === 'Left' ? 'lg:self-start lg:mb-auto lg:mr-32' : 'lg:self-end lg:mt-auto lg:ml-32'
            }`}
          >
            <ParallaxAnimation zoom>
              <Media
                cover
                media={data.media}
                widths={{ xs: 400, sm: 500, md: 688, lg: 600, xl: 700, xxl: 700 }}
                heights={{ xs: 251, sm: 241, md: 477, lg: 472, xl: 700, xxl: 700 }}
              />
            </ParallaxAnimation>
          </div>
        )}
      </div>
    </ModuleBase>
  );
};

export default RichTextAndOneMediaModule;
