import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import ResortNavDesktop from './ResortNavDesktop';
import ResortNavColumn from './ResortNavColumn';
import Close from 'assets/close.svg';
import { ResponsiveImage, Link, ModalPortal } from '@/components';
import { useLayoutEffect } from 'utils';
import tailwindConfig from '../../tailwind.config.js';
import ArrowUp from 'assets/arrowUp.svg';

const ResortNav = ({
  open,
  onClose,
  navItems,
  metadata,
  mainNav,
  addQueryParam,
  availabilityBar,
  filledHeader,
  logoElement,
}) => {
  const [activeMenu, setActiveMenu] = useState({ l0: {} });
  const [showUpArrow, setShowUpArrow] = useState(false);
  const { t } = useTranslation('common');

  const close = () => {
    setActiveMenu({ l0: {} });
    onClose();
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResize = () => {
    if (window.innerWidth >= parseInt(tailwindConfig.theme.screens.lg, 10)) {
      close();
    }
  };

  const onScroll = () => {
    if (window.innerWidth < parseInt(tailwindConfig.theme.screens.lg, 10) && window.scrollY > window.innerHeight) {
      setShowUpArrow(true);
    } else {
      setShowUpArrow(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <ModalPortal>
        <button
          className={classNames(
            'btn h-11 aspect-square p-2 !bg-brand-soft-white shadow-md ml-10 fixed right-4 duration-300',
            showUpArrow ? 'bottom-4 opacity-100' : '-bottom-5 opacity-0',
          )}
          onClick={scrollTop}
          aria-label={t('general.$book')}
        >
          <ArrowUp className="fill-black" />
        </button>
      </ModalPortal>

      <ResortNavDesktop
        navItems={navItems}
        mainNav={mainNav}
        addQueryParam={addQueryParam}
        availabilityBar={availabilityBar}
        filledHeader={filledHeader}
        scrollTop={scrollTop}
      />
      <div
        className={classNames(
          'fixed top-0 left-0 z-40 w-screen backdrop-blur transition-backdrop-filter duration-500',
          open ? 'h-dvh lg:h-screen disable-scroll' : 'h-0',
        )}
      />
      <div
        className={classNames(
          'bg-black fixed top-0 left-0 z-40 w-screen transition-opacity duration-500',
          open ? 'h-dvh lg:h-screen opacity-50' : 'h-0 opacity-0',
        )}
      />
      <div
        className={classNames(
          'fixed top-0 left-0 z-40 w-full h-dvh overflow-y-auto transition-transform duration-700 ease-out overflow-x-hidden bg-background-primary',
          { '-translate-x-full': !open },
        )}
      >
        <div className="absolute z-20 w-full h-[90px] lg:h-auto pt-0 lg:pt-[42px] lg:px-8 flex justify-between items-center border-b border-b-black/25 lg:border-b-0">
          <button
            onClick={close}
            className="btn z-10 hover:[&_svg]:stroke-black/50 [&_svg]:stroke-black !h-auto"
            aria-label={t('general.$close')}
          >
            <Close className="stroke-black w-8 h-8" role="presentation" />
          </button>
          {logoElement && (
            <Link
              link={logoElement.homeLink}
              className="h-6 sm:h-9 aspect-[39/8]"
              aria-label={t('general.$goToHomepage')}
              onClick={close}
            >
              <ResponsiveImage
                image={logoElement.logo}
                heights={{ xs: 50, md: 50, lg: 50, xl: 50 }}
                imgProps={{
                  className: 'h-full w-full object-contain invert',
                  height: '48',
                  width: '300',
                  alt: 'logo',
                }}
                dataNotLazy
              />
            </Link>
          )}
          <div className="w-[72px] lg:hidden" />
        </div>

        <ResortNavColumn
          navItems={navItems.navigationItems}
          activeMenu={activeMenu}
          level={0}
          setActiveMenu={(sub) => setActiveMenu({ l0: sub })}
          close={close}
          metadata={metadata}
          addQueryParam={addQueryParam}
        />
        <div className="lg:hidden absolute bottom-0 z-30 w-full px-6 py-4 flex justify-between bg-background-secondary gap-4 resort-nav-shadow-mobile">
          <button className="btn primary w-full h-12" onClick={() => addQueryParam({ contact: true })}>
            <span>{t('general.$contact')}</span>
          </button>
          {availabilityBar && (
            <button
              className="btn secondary w-full h-12 !bg-background-spotlight !border !border-background-spotlight"
              onClick={() => addQueryParam({ book: true })}
            >
              <span>{t('general.$book')}</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ResortNav;
