import React, { useRef, useState } from 'react';
import { ModuleBase, EntityCard, HeadingTag, Link } from '@/components';
import CarouselPagination from '@/components/general/CarouselPagination';

import Flicking from '@egjs/react-flicking';

import ArrowRight from 'assets/arrowRight.svg';
import { useTranslation } from 'next-i18next';
import { fixColor, isColorDark } from '@/utils/color-utils.js';
import classNames from 'classnames';

const CardCarouselOutOfScreenModule = ({ data }) => {
  const { headingTitle, description, primaryCta, cardRow } = data || {};
  const { t } = useTranslation('common');
  const carouselOuter = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [panelCount, setPanelCount] = useState(0);

  const willChange = (e) => {
    setActiveIndex(e.index);
  };

  const onReady = () => {
    setPanelCount(carouselOuter.current.panelCount);
  };

  const dark = isColorDark(fixColor(data?.backgroundColour));

  return (
    <ModuleBase data={data} className="overflow-hidden">
      <div className="container pr-0 pt-12 md:pt-14">
        <div
          className="description-data pr-6 md:pr-10 lg:pr-[108px] w-full mb-8  md:mb-10 lg:flex lg:justify-between"
          role="contentinfo"
          aria-label={t('general.$contactInfo')}
        >
          <div className="title-wrapper lg:max-w-[808px]">
            {headingTitle && (
              <HeadingTag
                data={headingTitle}
                className="main-title color-from-bg font-montserrat mb-4 text-base not-italic font-semibold leading-5 tracking-headline-m-tablet uppercase
                            md:text-xl md:leading-6 md:tracking-quote-reg-mobile"
              />
            )}
            {description && (
              <div
                dangerouslySetInnerHTML={{ __html: description }}
                className={`font-montserrat color-from-bg text-sm not-italic font-light leading-6 lg:text-base lg:leading-6.5 `}
              ></div>
            )}
          </div>

          {primaryCta && (
            <Link
              className="btn primary w-full md:w-fit first-link md:self-start color-from-bg mt-8 lg:mt-auto"
              link={primaryCta}
            >
              <span>{primaryCta.text}</span>
              <ArrowRight className="!ml-6" role="presentation" />
            </Link>
          )}
        </div>

        {cardRow.map((row, rIndex) => (
          <div key={rIndex} className="w-full">
            <Flicking
              ref={carouselOuter}
              align={'prev'}
              useResizeObserver={true}
              horizontal={true}
              circular={true}
              circularFallback="bound"
              className=""
              onReady={onReady}
              onWillChange={willChange}
            >
              {row.props.cards.map((card, index) => (
                <div
                  key={index}
                  className={classNames(
                    'mr-4 w-[300px] md:w-[400px] xl:w-[472px]',
                    card.moduleName !== 'ArticleCard' ? 'flex' : '',
                  )}
                >
                  <EntityCard data={card} dark={dark} className="w-full" />
                </div>
              ))}
            </Flicking>

            <CarouselPagination
              dark={dark}
              current={activeIndex}
              total={panelCount}
              className="mx-auto mt-10 pr-6 md:mt-16 md:pr-10 lg:mx-0 lg:ml-auto lg:pr-[108px]"
            />
          </div>
        ))}
      </div>
    </ModuleBase>
  );
};
export default CardCarouselOutOfScreenModule;
