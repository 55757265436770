import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { useLayoutEffect } from 'utils';
import classNames from 'classnames';

const Tabs = forwardRef(function Tabs(
  // eslint-disable-next-line no-unused-vars
  { tabs, dark, activeTab, onChange, className },
  ref,
) {
  const [underlineStyle, setUnderlineStyle] = useState({});

  const tabsContainer = useRef();

  useEffect(() => {
    moveUnderline();
  }, [activeTab]);

  useLayoutEffect(() => {
    window.addEventListener('resize', moveUnderline);
    return () => window.removeEventListener('resize', moveUnderline);
  }, []);

  const moveUnderline = () => {
    setTimeout(() => {
      if (tabsContainer?.current?.children) {
        Array.from(tabsContainer.current.children).forEach((element) => {
          if (element && element.classList && element.classList.contains('active-tab')) {
            setUnderlineStyle({ left: `${element.offsetLeft}px`, width: `${element.offsetWidth}px` });
          }
        });
      }
    }, 0);
  };

  const tabClick = (index, tab) => {
    if (onChange) {
      onChange(index, tab);
    }
  };

  return (
    <div
      ref={ref}
      className={classNames(
        className,
        'flex flex-wrap justify-between  overflow-x-auto overflow-y-hidden scrollbar-hide',
        dark ? 'text-black' : 'text-white',
      )}
    >
      {tabs && (
        <div className="container w-auto lg:w-full">
          <div ref={tabsContainer} className="tab-item-wrapper relative flex m-auto h-16 sm:h-20 w-max md:w-full">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={classNames(
                  'tab-item font-montserrat  cursor-pointer py-6 px-10 md:py-7 md:px-8 flex items-center justify-center transition-colors text-center whitespace-nowrap lg:whitespace-normal',
                  'text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase',
                  'md:text-sm md:tracking-button-reg',
                  'border-b',
                  dark ? 'border-brand-soft-white/50' : 'border-black/50',
                  activeTab === index
                    ? dark
                      ? 'active-tab text-brand-soft-white !border-brand-soft-white'
                      : 'active-tab text-black !border-black'
                    : dark
                      ? 'opacity-50 text-brand-soft-white'
                      : 'opacity-50 text-black',
                )}
                onClick={() => tabClick(index, tab)}
                role="button"
                aria-label={tab.tabTitle}
              >
                {tab.tabTitle}
              </div>
            ))}

            <div
              className={`absolute bottom-0 h-px md:h-px transition-all duration-500 ease-out ${
                dark ? 'bg-brand-soft-white' : 'bg-black'
              }`}
              style={underlineStyle}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default Tabs;
