import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Close from 'assets/close.svg';
import { useTranslation } from 'next-i18next';
import { Link } from '..';
import ArrowRight from 'assets/arrowRight.svg';

const ContactPopup = ({ contactDetails }) => {
  const [open, setOpen] = useState(false);
  const [closeDelay, setCloseDelay] = useState(false);
  const router = useRouter();

  const { t } = useTranslation('common');

  useEffect(() => {
    if (router.query.contact) {
      setOpen(true);
      setCloseDelay(true);
    } else {
      setOpen(false);
      setTimeout(() => {
        setCloseDelay(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.contact]);

  const onClose = () => {
    const query = { ...router.query };
    delete query.contact;
    router.replace(
      {
        query,
      },
      undefined,
      { shallow: true },
    );
  };

  return (
    <>
      <div
        className={classNames(
          'fixed top-0 left-0 z-40 w-screen backdrop-blur transition-backdrop-filter duration-500',
          open ? 'h-dvh lg:h-screen disable-scroll' : 'h-0',
        )}
      />
      <div
        className={classNames(
          'bg-black fixed top-0 left-0 z-40 w-screen transition-opacity duration-500',
          open ? 'h-dvh lg:h-screen opacity-75' : 'h-0 opacity-0',
        )}
      />

      <div
        className={classNames(
          'fixed top-0 left-0 z-50 transition-opacity duration-500 w-screen flex justify-center items-center overflow-hidden px-6',
          open ? 'opacity-1' : 'opacity-0',
          closeDelay ? 'h-dvh lg:h-screen' : 'h-0',
        )}
        onClick={onClose}
      >
        <div
          className="bg-brand-soft-white w-full sm:w-[448px] h-[522px] rounded-contact relative flex flex-col justify-between items-center shrink-0 px-10 py-8 lg:py-12"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <button
            onClick={onClose}
            className="btn absolute right-0 top-0 p-4 h-auto bg-transparent [&_svg]:stroke-black [&_svg]:hover:stroke-black/75"
            aria-label={t('general.$close')}
          >
            <Close className="stroke-black w-6 h-6" />
          </button>

          <p className="font-didot text-title-m italic font-normal leading-normal -tracking-card-m">
            {t('general.$contactUs2')}
          </p>

          {contactDetails.contacts?.map((contact, index) => {
            return [
              <div key={index} className="flex flex-col w-full items-center gap-[13.6px]">
                <div className="text-sm not-italic font-medium leading-normal tracking-button-reg uppercase font-montserrat">
                  {contact.contactTitle}
                </div>
                <a
                  href={`tel:${contact.phoneNumber}`}
                  className="text-xs italic font-normal leading-5 -tracking-contact underline font-montserrat"
                >
                  {contact.phoneNumber}
                </a>
                <a
                  href={`mailto:${contact.emailAddress}`}
                  className="text-xs italic font-normal leading-5 -tracking-contact underline font-montserrat"
                >
                  {contact.emailAddress}
                </a>
              </div>,
              <hr key={'_' + index} className="h-px w-full text-black opacity-15" />,
            ];
          })}

          <div className="flex flex-col items-center w-full">
            <div className="text-sm not-italic font-medium leading-normal tracking-button-reg uppercase font-montserrat text-black/50">
              {t('contacts.$otherenquiries')}
            </div>

            {contactDetails.ctaLink && contactDetails.ctaLink.text && (
              <Link className="btn primary border-black/25 mt-[13.6px] w-full" link={contactDetails.ctaLink}>
                <span>{contactDetails.ctaLink.text.toLocaleUpperCase(router.locale)}</span>
                <ArrowRight />
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPopup;
