import PropTypes from 'prop-types';
import { Link, ResponsiveImage } from '..';
import { useTranslation } from 'next-i18next';

const SmallProductCard = ({ data, dark }) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-col gap-8">
      <div className="image-wrapper w-full h-[204px] md:h-[376px]">
        <ResponsiveImage
          image={data.image}
          widths={{ xs: 300, md: 400, lg: 472, xl: 472 }}
          heights={{ xs: 204, md: 245, lg: 376, xl: 376 }}
        />
      </div>
      <div className="content-wrapper flex flex-col h-full justify-between">
        <div className="text-wrapper color-from-bg">
          <p
            role="heading"
            aria-level="7"
            className="font-montserrat text-base md:text-xl leading-5 md:leading-6 font-semibold tracking-headline-m-tablet md:tracking-title-m uppercase mb-3 md:mb-4"
          >
            {data.headingTitle}
          </p>
          <p className="font-montserrat text-xs md:text-sm leading-5 md:leading-6 font-normal opacity-75">
            {data.description}
          </p>
        </div>
        <div>
          <div className="my-6 md:my-8 md:grid md:grid-cols-2">
            {data.keyFeatures.map((feature, index) => (
              <div key={`feature-${index}`} className="py-3 flex gap-3 items-center">
                <div className="w-6 h-6">
                  <ResponsiveImage
                    image={feature.logo}
                    imgProps={{
                      className: dark ? 'invert w-full h-full block object-cover' : 'w-full h-full block object-cover',
                    }}
                  />
                </div>
                <span className="color-from-bg text-sm leading-6 font-montserrat font-normal">{feature.text}</span>
              </div>
            ))}
          </div>

          <Link link={data.primaryCta} className="btn primary color-from-bg w-full md:w-fit">
            {t('general.$explore')}
          </Link>
        </div>
      </div>
    </div>
  );
};

SmallProductCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SmallProductCard;
