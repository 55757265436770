import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { ModuleBase, CMSModule } from '@/components';
import { openLink, useLayoutEffect } from '@/utils';
import classnames from 'classnames';

const TabsModule = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [underlineStyle, setUnderlineStyle] = useState({});

  const tabsContainer = useRef();
  const router = useRouter();

  useEffect(() => {
    if (data?.tabs && activeIndex === null) {
      let newActiveIndex = null;
      const hash = router.asPath.split('#')[1];

      if (hash && hash.startsWith('t') && hash.length > 4) {
        const mid = hash.substring(1, 4);
        if (mid === data.moduleId.substring(0, 3)) {
          const tabIndex = hash.substring(4);
          if (!isNaN(tabIndex)) {
            newActiveIndex = Number(tabIndex);
          }
        }
      }

      if (newActiveIndex === null) {
        for (let i = 0; i < data.tabs.length; i++) {
          const tab = data.tabs[i];
          if (!tab.tabLink && tab.tabModules) {
            newActiveIndex = i;
            break;
          }
        }
      }
      setActiveIndex(newActiveIndex);
    }
  }, [data, router.asPath, activeIndex]);

  useEffect(() => {
    moveUnderline();
  }, [activeIndex]);

  useLayoutEffect(() => {
    window.addEventListener('resize', moveUnderline);
    return () => window.removeEventListener('resize', moveUnderline);
  }, []);

  const moveUnderline = () => {
    setTimeout(() => {
      if (tabsContainer?.current?.children) {
        Array.from(tabsContainer.current.children).forEach((element) => {
          if (element && element.classList && element.classList.contains('active-tab')) {
            setUnderlineStyle({ left: `${element.offsetLeft}px`, width: `${element.offsetWidth}px` });
          }
        });
      }
    }, 0);
  };

  const tabClick = (tab, index) => {
    if (tab.tabLink) {
      openLink(router, tab.tabLink);
    } else {
      router.replace({
        hash: `t${data.moduleId.substring(0, 3)}${index}`,
      });
      setActiveIndex(index);
    }
  };

  return (
    <ModuleBase data={data} className="bg-background-primary">
      <div className="container flex overflow-x-auto overflow-y-hidden pb-4 scrollbar-hide">
        {data.tabs && (
          <div ref={tabsContainer} className="relative flex w-max md:w-full">
            {data.tabs.map((tab, index) => (
              <div
                key={index}
                className={classnames(
                  'flex grow cursor-pointer items-center justify-center whitespace-nowrap border-b border-brand-black/25 p-6 md:px-8 md:py-7 text-center font-montserrat transition-colors lg:whitespace-normal font-medium uppercase text-xs md:text-sm !leading-normal tracking-quote-reg-mobile md:tracking-button-reg',
                  activeIndex === index ? 'active-tab text-brand-black' : 'text-brand-black/50',
                )}
                onClick={() => tabClick(tab, index)}
                role="button"
              >
                {tab.tabTitle}
              </div>
            ))}

            <div
              className="absolute bottom-0 h-px transition-all duration-500 ease-out bg-black"
              style={underlineStyle}
            />
          </div>
        )}
      </div>
      <div className="relative">
        {data.tabs &&
          data.tabs.map((tab, index) => (
            <div
              key={index}
              className={classnames(
                'top-0 pb-0 pt-0 transition-opacity duration-500 ease-out',
                activeIndex === index ? 'relative opacity-100' : 'absolute h-0 w-full overflow-hidden opacity-0',
              )}
            >
              {tab.tabModules.map((module) => (
                <CMSModule key={module.moduleId} module={module} />
              ))}
            </div>
          ))}
      </div>
    </ModuleBase>
  );
};

export default TabsModule;
