import React from 'react';
import PropTypes from 'prop-types';

const HeadingTag = ({ data, children, ...props }) => {
  const { htag, heading } = data || {};
  let polishedHeading = heading?.replace(/<(p|h[0-6])>/g, '').replace(/<\/(p|h[0-6])>/g, '');
  const content = heading ? { dangerouslySetInnerHTML: { __html: polishedHeading } } : children;

  let accessibilityLevel = '7';
  if (htag && parseInt(htag[1]) > 0) {
    accessibilityLevel = htag[1];
  }

  return React.createElement(htag?.toLowerCase() || 'div', {
    role: 'heading',
    'aria-level': accessibilityLevel,
    ...props,
    ...content,
  });
};

HeadingTag.propTypes = {
  data: PropTypes.exact({
    htag: PropTypes.string,
    heading: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
};

export default HeadingTag;
