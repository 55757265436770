import { useTranslation } from 'next-i18next';
import { ModuleBase, AvailabilityForm } from 'components';
import { useRouter } from 'next/router';

const AvailabilityBarModel = ({ data }) => {
  const { t } = useTranslation('common');

  const router = useRouter();

  const openBook = () => {
    router.replace(
      {
        query: { ...router.query, book: true },
      },
      undefined,
      { shallow: true },
    );
  };

  return (
    <ModuleBase data={data} className="relative">
      <div
        className="absolute bottom-0 w-full z-10
        backdrop-blur-[10px] transition-backdrop-filter duration-500 bg-black/50 h-[63px] -top-[63px] sm:h-[71px] sm:-top-[71px] lg:h-[84px] lg:-top-[84px]"
      >
        <div
          className="container flex h-full justify-between items-center xl:px-[108px] py-4 
        [&_.form-item]:w-64 [&_.form-item.resort]:w-64 [&_.form-item.dates]:w-64 [&_.form-items-container]:md:gap-0 [&_.form-items-container]:xl:gap-6
        [&_.form-items-container]:hidden [&_.form-items-container]:lg:flex
        [&_.search-container]:hidden [&_.search-container]:lg:flex
        [&_.spacer]:w-px [&_.spacer]:bg-brand-soft-white
        [&_.main-button]:!border-b-transparent
        [&_.number-input_input]:!text-black
        [&_.arrow_svg]:!rotate-180 [&_.open_.arrow_svg]:!rotate-0
        [&_.arrow]:border [&_.arrow]:border-brand-soft-white/50 [&_.arrow]:rounded-full [&_.arrow]:h-7 [&_.arrow]:min-w-7 [&_.arrow]:flex [&_.arrow]:items-center [&_.arrow]:justify-center
        [&_.arrow_svg]:!fill-brand-soft-white [&_.dropdown.open_.arrow]:border-black/50 [&_.dropdown.open_.arrow_svg]:!fill-black [&_.dropdown.open_.popover-title.placeholder]:!text-black
        [&_.datepicker.open_.arrow]:border-black/50 [&_.datepicker.open_.arrow_svg]:!fill-black [&_.datepicker.open_.popover-title.placeholder]:!text-black
        [&_.popover-title.placeholder]:!text-brand-soft-white [&_.popover-title]:!text-brand-soft-white [&_.dropdown.open_.popover-title]:!text-black [&_.datepicker.open_.popover-title]:!text-black
        [&_.popover-title]:!text-xs  [&_.popover-title]:!leading-6 [&_.popover-title]:!font-normal [&_.popover-title]:!tracking-quote-reg-mobile [&_.popover-title]:!uppercase
        [&_.option]:!text-sm [&_.option]:!leading-6 [&_.option]:!normal-case [&_.option]:py-3 [&_.option]:!font-normal
        [&_.arrow_svg]:scale-75
        "
        >
          <button
            className="text-brand-soft-white font-montserrat text-center text-xs not-italic font-semibold leading-normal tracking-quote-reg-mobile uppercase w-full lg:w-auto h-full"
            onClick={openBook}
          >
            {t('booking.$bookyourstay')}
          </button>

          <AvailabilityForm availabilityData={data} searchClass="secondary" placement="top" />
        </div>
      </div>
    </ModuleBase>
  );
};

export default AvailabilityBarModel;
