import React, { useState, useEffect, useRef } from 'react';
import { pushValuesToRouteQuery, assignValuesFromRouteQuery } from 'utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import PaginatorArrow from 'assets/paginatorArrow2.svg';
import { isColorDark } from 'utils';

const Pagination = ({ pageSize, totalCount, onChange, queryMode, backgroundColour, className, ...props }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [btns, setBtns] = useState([]);
  const totalPages = useRef(1);
  const router = useRouter();
  const { t } = useTranslation('common');

  useEffect(() => {
    const pageObj = { page: 1 };
    assignValuesFromRouteQuery(router, pageObj);
    setCurrentPage(Number(pageObj.page || 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  useEffect(() => {
    totalPages.current = Math.ceil(totalCount / pageSize);
    const newBtns = [];
    for (let page = 1; page <= totalPages.current; page++) {
      if (totalPages.current > 4) {
        if (
          page === 1 ||
          currentPage === page ||
          currentPage - 1 === page ||
          currentPage + 1 === page ||
          (page === 2 && currentPage === 4) ||
          (page === totalPages.current && currentPage === totalPages.current - 2) ||
          (currentPage < 4 && totalPages.current > 3 && page < 4) ||
          (currentPage === totalPages.current && page === currentPage - 2)
        ) {
          newBtns.push({ label: page, value: page });
        } else if (
          (page === 2 && currentPage > 3) ||
          (page !== 2 && page === totalPages.current && totalPages.current > 3)
        ) {
          newBtns.push({ label: '...', value: page });
        }
      } else {
        newBtns.push({ label: page, value: page });
      }
    }
    setBtns(newBtns);
  }, [pageSize, totalCount, currentPage]);

  const setPage = (newPage) => {
    if (queryMode) {
      pushValuesToRouteQuery(router, {
        page: newPage > 1 ? newPage : null,
      });
    }
    if (onChange) {
      onChange({
        page: newPage,
      });
    }
  };

  if (totalPages.current <= 1) return;

  return (
    <div className={classNames('flex justify-center items-center pagination mt-12 md:mt-[72px]', className)} {...props}>
      <button
        className="btn small secondary circle color-from-bg  mx-3 w-10 h-10 p-2.5 rounded-[56px] hover:!bg-brand-soft-white"
        disabled={currentPage <= 1}
        onClick={() => setPage(currentPage - 1)}
        aria-label={t('general.$previous')}
      >
        <PaginatorArrow className="-rotate-180" />
      </button>

      {btns.map((btn) => (
        <button
          className={`text-sm not-italic font-medium leading-5 tracking-button-reg ${
            isColorDark(backgroundColour) && currentPage === btn.value
              ? 'bg-background-primary '
              : 'bg-background-spotlight '
          } ${
            !isColorDark(backgroundColour) && currentPage !== btn.value ? 'bg-transparent !text-black' : ''
          } ${classNames(
            'hidden lg:block btn text small mx-3 px-6 w-auto',
            currentPage === btn.value && 'text-white',
          )}`}
          key={btn.value}
          disabled={btn.label === '...'}
          onClick={() => setPage(btn.value)}
        >
          {btn.label} {currentPage === btn.value}
        </button>
      ))}

      <div className="wrapper lg:hidden ">
        <span className="font-montserrat text-sm not-italic font-medium leading-5 tracking-button-reg uppercase">
          {currentPage}
        </span>
        <span className="mx-1 font-montserrat  text-sm not-italic font-medium leading-5 tracking-button-reg uppercase">
          {t('general.$pagination')}
        </span>
        <span className="font-montserrat text-sm not-italic font-medium leading-5 tracking-button-reg uppercase">
          {totalPages.current}
        </span>
      </div>

      <button
        className="btn small secondary circle color-from-bg mx-3 w-10 h-10 p-2.5 rounded-[56px] hover:!bg-brand-soft-white"
        disabled={currentPage >= totalPages.current}
        onClick={() => setPage(currentPage + 1)}
        aria-label={t('general.$next')}
      >
        <PaginatorArrow />
      </button>
    </div>
  );
};
export default Pagination;
