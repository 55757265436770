import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import NavColumn from './NavColumn';
import Close from 'assets/close.svg';
import { ResponsiveImage, Link } from '@/components';

const Nav = ({ open, onClose, navItems, metadata, addQueryParam, availabilityBar, logoElement, showHomeLink }) => {
  const [activeMenu, setActiveMenu] = useState({ l0: {} });
  const { t } = useTranslation('common');

  const close = () => {
    setActiveMenu({ l0: {} });
    onClose();
  };

  return (
    <>
      <div
        className={classNames(
          'fixed top-0 left-0 z-40 w-screen backdrop-blur transition-backdrop-filter duration-500',
          open ? 'h-dvh lg:h-screen disable-scroll' : 'h-0',
        )}
      />
      <div
        className={classNames(
          'bg-black fixed top-0 left-0 z-40 w-screen transition-opacity duration-500',
          open ? 'h-dvh lg:h-screen opacity-50' : 'h-0 opacity-0',
        )}
        onClick={close}
      />

      <div
        className={classNames(
          'fixed top-0 left-0 z-40 w-full lg:w-[calc(100vw-120px)] h-dvh lg:h-screen overflow-y-auto transition-transform duration-700 ease-out dark:bg-gray-800 overflow-x-hidden',
          { '-translate-x-full': !open },
        )}
      >
        <div className="absolute top-0 right-0 h-full w-2/3" onClick={close}></div>
        <div className="absolute z-20 w-full h-[90px] lg:h-auto pt-0 lg:pt-[42px] lg:px-8 flex justify-between items-center border-b border-b-black/25 lg:border-b-0 lg:pointer-events-none">
          <button
            onClick={close}
            className="btn z-10 hover:[&_svg]:stroke-black/50 [&_svg]:stroke-black !h-auto pointer-events-auto"
            aria-label={t('general.$close')}
          >
            <Close className="stroke-black w-8 h-8" role="presentation" />
          </button>
          {logoElement && (
            <Link
              link={logoElement.homeLink}
              className="aspect-[39/8] h-6 sm:h-9 lg:hidden pointer-events-auto"
              aria-label={t('general.$goToHomepage')}
              onClick={close}
            >
              <ResponsiveImage
                image={logoElement.logo}
                heights={{ xs: 50, md: 50, lg: 50, xl: 50 }}
                imgProps={{
                  className: 'h-full w-full object-contain invert',
                  height: '48',
                  width: '300',
                  alt: 'logo',
                }}
                dataNotLazy
              />
            </Link>
          )}
          <div className="w-20 h-full lg:hidden" />
        </div>
        <NavColumn
          navItems={navItems}
          activeMenu={activeMenu}
          level={0}
          setActiveMenu={(sub) => setActiveMenu({ l0: sub })}
          close={close}
          metadata={metadata}
          addQueryParam={addQueryParam}
          showHomeLink={showHomeLink}
        />
        <div className="lg:hidden absolute bottom-0 z-30 w-full px-6 py-4 flex justify-between bg-brand-soft-white gap-4 border-t border-t-black/25">
          <button className="btn primary w-full h-12" onClick={() => addQueryParam({ contact: true })}>
            <span>{t('general.$contact')}</span>
          </button>
          {availabilityBar && (
            <button
              className="btn secondary w-full h-12 !bg-background-spotlight !border !border-background-spotlight"
              onClick={() => addQueryParam({ book: true })}
            >
              <span>{t('general.$book')}</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Nav;
